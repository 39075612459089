import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

import { MarketCalculusCard } from '../../../../components/preview/Preview'
import { useSDSsearchParams } from '../../../../store/searchDataStore'
import ChangeDataTable from './ChangeDataTable'
import McPageHelpers from '../../McPageHelpers'
import MCTitleCard from '../../../../components/title-card/MCTitleCard'
import MultiValueLineAndBarChart from '../../../../components/amcharts/MultiValueLineAndBarChart'
import { MARKET_CHANGE_CHART_TYPES } from '../../../../utils/AMChartsSettings'

const sortByProp = (array, prop, subProp = null) => {
  return (
    subProp
      ? array?.sort((a, b) => b?.[prop]?.[subProp] - a?.[prop]?.[subProp])
      : array?.sort((a, b) => b?.[prop] - a?.[prop])
  )
}

const formatChangeBarData = (data) => {
  const filtered = data?.filter((item) => item.countryId !== 0)
  const sorted = sortByProp(filtered, 'worldChangeData', 'absoluteChange')

  const firstAndLast10Item = sorted?.length > 20
    ? [...filtered?.slice(0, 10), ...filtered?.slice(filtered?.length - 10, filtered?.length)]
    : filtered

  const formatted = firstAndLast10Item?.map((item) => ({
    country: McPageHelpers.findCountryName(item.countryId),
    absolute: Math.round(McPageHelpers.numDivisionByThousand(item.worldChangeData.absoluteChange)),
    percent: (item.worldChangeData.percentChange === 'Infinity' ? 0 : item.worldChangeData.percentChange * 100)
  }))

  return formatted
}

const ChangeCard = forwardRef((props, ref) => {
  const {
    id,
    data,
    isYearTable,
    title,
    graphTitle,
    code,
    graphicCode,
    subTitle,
    isPdf,
    onTableRowClick
  } = props
  const { t } = useTranslation()
  const searchParams = useSDSsearchParams()

  const formattedBarData = formatChangeBarData(data?.countryChanges) ?? []

  return (
    <MarketCalculusCard className={`${!data?.countryChanges?.length && 'loading-div'} my-3`}>
      <Row>
        <Col md="12">
          <div id={id + McPageHelpers.FOR_PDF_ID}>
            <MCTitleCard
              chartTitle={graphTitle}
              code={graphicCode}
              codeColor="light"
              textColor={'#09c2de'}
              chartSubTitle={subTitle ?? (searchParams.hsCode ?? '')}
            />
            <MultiValueLineAndBarChart
              chartId={id + graphicCode}
              yFormatterType={MARKET_CHANGE_CHART_TYPES.QUANTITY}
              chartData={formattedBarData}
              yTitle={t('value_with_dollar_t')}
              yTitleSecond={t('value_with_percent')}
            />
          </div>
        </Col>
        <Col md="12">
          <MCTitleCard
            chartTitle={title}
            code={code}
            codeColor="info"
            textColor={'#09c2de'}
            chartSubTitle={subTitle ?? (searchParams.hsCode ?? '')}
          />
          <ChangeDataTable
            ref={ref}
            data={data}
            isYearTable={isYearTable}
            pagination
            isPdf={isPdf}
            onTableRowClick={onTableRowClick}
          />
        </Col>
      </Row>
    </MarketCalculusCard>
  )
})

export default ChangeCard
