import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const RSelect = (props) => {
  const { t } = useTranslation()
  return (
    <div className="form-control-select">
      <Select
        className={`react-select-container ${props.className ? props.className : ''}`}
        classNamePrefix="react-select"
        noOptionsMessage={() => t('no_option')}
        placeholder={t('sp_select')}
        {...props}
      />
    </div>
  )
}

export default RSelect
