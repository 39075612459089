import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useDataStoreActions } from '../../store/dataStore'
import { useSearchDataStoreActions } from '../../store/searchDataStore'
import { useRawStoreActions } from '../../store/rawStore'
import { useEndpointStatusActions } from '../../store/endpointStatus'
import { useGlobalStoreData } from '../../store/globalStore'

import CONSTANTS from '../../utils/Constants'
import McPageHelpers, { findCountry } from '../../pages/mc-components/McPageHelpers'
import { getUrlParamsObj } from '../../services/urlServices'

import ErrorModal from '../error-modal/error-modal'

const SummaryViewSearchHandler = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const { resetRawStore } = useRawStoreActions()

  const { resetData } = useDataStoreActions()

  const {
    setSearchParamsWithPrev,
    startSearching
  } = useSearchDataStoreActions()

  const { setEndpointStatus } = useEndpointStatusActions()

  const userGSD = useGlobalStoreData.user()
  const searchCountrySet = userGSD?.rules?.find(item => item.type === 'SEARCH_COUNTRY_SET')
  const allowedCountries = searchCountrySet?.parameters?.allowedSearchCountries

  const [modalFail, setModalFail] = useState(false)
  const [errorModalMessage, setErrorModalMessage] = useState()
  const [errorModalTitle, setErrorModalTitle] = useState()
  const [errorModalHref, setErrorModalHref] = useState(null)

  const fireErrorModal = (title, message, href = null) => {
    setErrorModalHref(href)
    setErrorModalTitle(title)
    setErrorModalMessage(message)
    setModalFail(true)
  }

  const handleSearchByCountryToExport = (countryCode) => {
    const predefinedUrlParams = getUrlParamsObj()

    if (predefinedUrlParams?.countryToExport !== countryCode) {
      const searchParams = {
        ...predefinedUrlParams,
        countryToExport: countryCode
      }

      setSearchParamsWithPrev(searchParams)

      setEndpointStatus('errorMessage')
      resetData()
      resetRawStore()
      startSearching()
    }
  }

  const checkAndSearchCountryToExport = (countryCode) => {
    if (allowedCountries && !allowedCountries.includes(countryCode) && !McPageHelpers.findCountryName(allowedCountries).includes(CONSTANTS.WORLD_COUNTRY_CODE)) {
      fireErrorModal(
        t('error_search_country_set_text', { exporter: McPageHelpers.findCountryName(countryCode) }),
        allowedCountries && allowedCountries.map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
    } else {
      handleSearchByCountryToExport(countryCode)

      toast.success(t('importerSelectedToast', { countryName: McPageHelpers.findCountryName(countryCode) }), {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
    }
  }

  const onTableRowClick = (row, e) => {
    const country = findCountry(row?.partnerCode || row?.countryId)

    if (e?.target?.innerText === country?.countryName) { // click country name cell
      if (country?.countryCode) {
        checkAndSearchCountryToExport(country.countryCode)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onTableRowClick
  }))

  return (
    <ErrorModal
      isOpen={modalFail}
      toggleModalFail={() => setModalFail((prevState) => !prevState)}
      title={errorModalTitle}
      message={errorModalMessage}
      linkText={errorModalHref ? t('error_anonymous_daily_search_linkText') : null}
      href={errorModalHref}
      clearErrorState={() => { setEndpointStatus('errorMessage') }}
    />
  )
})

export default SummaryViewSearchHandler
