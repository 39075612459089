import React, { useEffect } from 'react'
import Icon from '../../components/icon/Icon'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useGlobalStoreData } from '../../store/globalStore'
import { useSDSimaginalUrlSearchParams } from '../../store/searchDataStore'
import { useTranslation } from 'react-i18next'

const MenuItem = (props) => {
  const {
    icon,
    link,
    text,
    sub,
    newTab,
    sidebarToggle,
    mobileView,
    badge,
    currentLanguage,
    onClick
  } = props

  let currentUrl
  const { t } = useTranslation()
  const urlSearchParams = useSDSimaginalUrlSearchParams()

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname
  } else {
    currentUrl = null
  }

  const toggleActionSidebar = (e) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e)
    }
  }

  const menuHeight = (el) => {
    let totalHeight = []
    for (let i = 0; i < el?.length; i++) {
      const margin =
        parseInt(window.getComputedStyle(el[i]).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).marginBottom.slice(0, -2))
      const padding =
        parseInt(window.getComputedStyle(el[i]).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).paddingBottom.slice(0, -2))
      const height = el[i].clientHeight + margin + padding
      totalHeight.push(height)
    }
    totalHeight = totalHeight.reduce((sum, value) => (sum += value))
    return totalHeight
  }

  const makeParentActive = (el, childHeight) => {
    const element = el.parentElement.parentElement.parentElement
    const wrap = el.parentElement.parentElement
    if (element.classList[0] === 'nk-menu-item') {
      element.classList.add('active')
      const subMenuHeight = menuHeight(el.parentNode.children)
      wrap.style.height = subMenuHeight + childHeight - 50 + 'px'
      makeParentActive(element)
    }
  }

  useEffect(() => {
    const element = document.getElementsByClassName('nk-menu-item active current-page')
    const arrayElement = [...element]

    arrayElement.forEach((dom) => {
      if (dom.parentElement.parentElement.parentElement.classList[0] === 'nk-menu-item') {
        dom.parentElement.parentElement.parentElement.classList.add('active')
        const subMenuHeight = menuHeight(dom.parentNode.children)
        dom.parentElement.parentElement.style.height = subMenuHeight + 'px'
        makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight)
      }
    })
  }, [])

  const menuToggle = (e) => {
    e.preventDefault()
    const self = e.target.closest('.nk-menu-toggle')
    const parent = self.parentElement
    const subMenu = self.nextSibling
    const subMenuItem = subMenu.childNodes
    const parentSiblings = parent.parentElement.childNodes
    const parentMenu = parent.closest('.nk-menu-wrap')
    // For Sub Menu Height
    const subMenuHeight = menuHeight(subMenuItem)
    // Get parent elements
    const getParents = (el, parentSelector) => {
      parentSelector = document.querySelector('.nk-menu')
      if (parentSelector === undefined) {
        parentSelector = document
      }
      const parents = []
      let p = el.parentNode
      while (p !== parentSelector) {
        const o = p
        parents.push(o)
        p = o.parentNode
      }
      parents.push(parentSelector)
      return parents
    }
    const parentMenus = getParents(self)
    if (!parent.classList.contains('active')) {
      // For Parent Siblings
      for (let j = 0; j < parentSiblings?.length; j++) {
        parentSiblings[j].classList.remove('active')
        if (typeof parentSiblings[j].childNodes[1] !== 'undefined') {
          parentSiblings[j].childNodes[1].style.height = 0
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains('sub-opened')) {
          parentMenu.classList.add('sub-opened')

          for (let l = 0; l < parentMenus?.length; l++) {
            if (typeof parentMenus !== 'undefined') {
              if (parentMenus[l].classList.contains('nk-menu-wrap')) {
                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + 'px'
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add('active')
      subMenu.style.height = subMenuHeight + 'px'
    } else {
      parent.classList.remove('active')
      if (parentMenu !== null) {
        parentMenu.classList.remove('sub-opened')
        for (let k = 0; k < parentMenus?.length; k++) {
          if (typeof parentMenus !== 'undefined') {
            if (parentMenus[k].classList.contains('nk-menu-wrap')) {
              parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + 'px'
            }
          }
        }
      }
      subMenu.style.height = 0
    }
  }

  const menuItemClass = classNames({
    'nk-menu-item': true,
    'has-sub': sub,
    'active current-page': currentUrl === process.env.PUBLIC_URL + link
  })

  if (onClick) {
    return (
      <li className='nk-menu-item'>
        <a onClick={onClick} role="button" className='nk-menu-link'>
          <span className="nk-menu-text">{t(text)}</span>
        </a>
      </li>
    )
  } else {
    return (
      <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
        {newTab
          ? (
          <NavLink
            to={`${process.env.PUBLIC_URL + '/' + currentLanguage + link + '?' + urlSearchParams}`}
            target="_blank"
            rel="noopener noreferrer"
            className="nk-menu-link"
          >
            {icon
              ? (
              <span className="nk-menu-icon">
                <Icon name={icon}/>
              </span>
                )
              : null}
            <span className="nk-menu-text">{t(text)}</span>
          </NavLink>
            )
          : (
          <NavLink
            to={process.env.PUBLIC_URL + '/' + currentLanguage + link + '?' + urlSearchParams}
            className={`nk-menu-link${sub ? ' nk-menu-toggle' : ''}`}
            onClick={sub ? menuToggle : null}
          >
            {icon
              ? (
              <span className="nk-menu-icon">
                <Icon name={icon}/>
              </span>
                )
              : null}
            <span className="nk-menu-text">{t(text)}</span>
            {badge && <span className="nk-menu-badge">{badge}</span>}
          </NavLink>
            )}
        {sub
          ? (
          <div className="nk-menu-wrap">
            <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView}/>
          </div>
            )
          : null}
      </li>
    )
  }
}

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, currentLanguage, ...props }) => {
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub.map((item, i) => (
        <MenuItem
          key={i}
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          badge={item.badge}
          newTab={item.newTab}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
          currentLanguage={currentLanguage}
        />
      ))}
    </ul>
  )
}

const MobileMenu = (props) => {
  const {
    data,
    sidebarToggle,
    mobileView,
    userRoles,
    openB2bInfoModal
  } = props

  const languageGSD = useGlobalStoreData.language()

  return (
    <ul className="nk-menu nk-menu-main ui-s2">
      {!userRoles.B2B_ONLY && data.map((item) => (
        <MenuItem
          key={item.text}
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          badge={item.badge}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
          currentLanguage={languageGSD}
        />
      ))}
      <MenuItem
        link={`/mailing${userRoles?.B2B_PERSONNEL ? '/users' : ''}`}
        text='menu.b2bMail'
        sidebarToggle={sidebarToggle}
        mobileView={mobileView}
        currentLanguage={languageGSD}
        onClick={openB2bInfoModal}
      />
    </ul>
  )
}

export default MobileMenu
