export const UserTypes = {
  B2B_PERSONNEL: 'B2B_PERSONNEL',
  B2B_USER: 'B2B_USER',
  USER: 'USER',
  ADMIN: 'ADMIN',
  ANONYMOUS: 'ANONYMOUS'
}

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const B2B_RULE_TYPES = [
  'B2B_HS_CODE_COUNT',
  'B2B_HS_CODE_SET',
  'B2B_SEARCH_COUNTRY_SET',
  'B2B_CAMPAIGN_COUNT',
  'B2B_TOTAL_EMAIL_COUNT'
]
