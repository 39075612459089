import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { localizationAMChart, MARKET_CHANGE_CHART_TYPES, numberFormatter } from '../../utils/AMChartsSettings'
import { decimalAdjust } from '../../utils/Utils'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const getAdjustedAbsolute = (maxVal, minVal) => {
  const expMax = Math.floor(Math.log10(Math.abs(maxVal)))
  const expMin = Math.floor(Math.log10(Math.abs(minVal)))
  const exponent = Math.max(expMax, expMin)

  const managedExp = exponent > 1 ? exponent - 1 : exponent
  const result = {
    max: maxVal,
    min: minVal
  }

  result.max = expMax === exponent ? decimalAdjust.ceil(maxVal, managedExp) : decimalAdjust.ceil(maxVal, exponent)
  result.min = expMin === exponent ? decimalAdjust.floor(minVal, managedExp) : decimalAdjust.floor(minVal, exponent)

  return result
}

const getAdjustedPercent = (maxVal, minVal, adjustedAbsolute) => {
  const expMax = Math.floor(Math.log10(Math.abs(maxVal)))

  const managedMax = decimalAdjust.ceil(maxVal, expMax)

  const result = {
    max: managedMax,
    min: (managedMax / adjustedAbsolute.max) * adjustedAbsolute.min
  }

  if (Math.abs(minVal) > Math.abs(result.min)) {
    const multiplier = minVal / result.min

    result.min *= multiplier
    result.max *= multiplier
  }

  return result
}

const alignedYaxisMinMax = (data) => {
  const absoluteArray = data.map((item) => item.absolute)
  const percentArray = data.map((item) => item.percent)

  const absoluteMax = Math.max(...absoluteArray)
  const absoluteMin = Math.min(...absoluteArray)
  const percentMax = Math.max(...percentArray)
  const percentMin = Math.min(...percentArray)

  const absoluteRatio = Math.abs(absoluteMax / absoluteMin)
  const percentRatio = Math.abs(percentMax / percentMin)

  const calculated = {
    absolute: {
      max: null,
      min: null
    },
    percent: {
      max: null,
      min: null
    }
  }

  let allSameSign = false

  if (absoluteMax > 0 && absoluteMin > 0 && percentMax > 0 && percentMin > 0) {
    allSameSign = true
    calculated.absolute.min = 0
    calculated.percent.min = 0
  } else if (absoluteMax < 0 && absoluteMin < 0 && percentMax < 0 && percentMin < 0) {
    allSameSign = true
    calculated.absolute.max = 0
    calculated.percent.max = 0
  }

  if (!allSameSign && absoluteRatio !== percentRatio) {
    calculated.absolute = getAdjustedAbsolute(absoluteMax, absoluteMin)
    calculated.percent = getAdjustedPercent(percentMax, percentMin, calculated.absolute)
  }

  return calculated
}

const MultiValueLineAndBarChart = ({
  chartId,
  yFormatterType,
  chartData,
  yTitle,
  yTitleSecond
}) => {
  const [data, setData] = useState([])
  const prevChartDataRef = useRef()
  const rootRef = useRef(null)

  useEffect(() => {
    const isDataChanged = JSON.stringify(prevChartDataRef.current) !== JSON.stringify(chartData)

    if (isDataChanged) {
      setData([...chartData].reverse())
      prevChartDataRef.current = chartData
    }
  }, [chartData])

  const aligned = alignedYaxisMinMax(data)

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      layout: root.verticalLayout,
      numberFormat: numberFormatter(yFormatterType),
      exportable: true
    }))

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 1,
      minorGridEnabled: true
    })

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'country',
      renderer: xRenderer
    }))

    xRenderer.grid.template.setAll({
      location: 1
    })

    xRenderer.labels.template.setAll({
      rotation: -20,
      paddingTop: 20,
      fontSize: 10
    })

    xAxis.data.setAll(data)

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: aligned.absolute.min,
      max: aligned.absolute.max,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
        // minHeight: 70
      })
    }))

    const paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true, minHeight: 70 })
    const paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: aligned.percent.min,
      max: aligned.percent.max,
      // syncWithAxis: yAxis,
      strictMinMax: true,
      renderer: paretoAxisRenderer
    }))

    chart.zoomOutButton.set('forceHidden', true)
    paretoAxisRenderer.grid.template.set('forceHidden', true)
    paretoAxis.set('numberFormat', numberFormatter(MARKET_CHANGE_CHART_TYPES.PERCENTAGE))

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis,
      yAxis,
      valueYField: 'absolute',
      categoryXField: 'country',
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        getFillFromSprite: true,
        labelText: '{categoryX}: {valueY}'
      })
    }))

    series.columns.template.setAll({
      // tooltipText: '{categoryX}: {valueY}',
      // tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 6,
      cornerRadiusTR: 6
    })

    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.dataItems.indexOf(target.dataItem))
    })

    const paretoSeries = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis,
      yAxis: paretoAxis,
      valueYField: 'percent',
      categoryXField: 'country',
      stroke: root.interfaceColors.get('alternativeBackground'),
      maskBullets: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        getFillFromSprite: true,
        labelText: '{country}: %{valueY}'
      })
    }))

    paretoSeries.bullets.push(function () {
      const circle = am5.Circle.new(root, {
        radius: 8,
        fill: series.get('fill'),
        stroke: root.interfaceColors.get('alternativeBackground'),
        strokeWidth: 2,
        toggleKey: 'active'
        // tooltipText: '{country}: %{valueY}'
      })
      return am5.Bullet.new(root, {
        sprite: circle
      })
    })

    chart.set('cursor', am5xy.XYCursor.new(root, {
      // behavior: 'zoomXY',
      xAxis
    }))

    xAxis.set('tooltip', am5.Tooltip.new(root, {
      pointerOrientation: 'horizontal',
      getFillFromSprite: true,
      themeTags: ['axis']
    }))

    series.data.setAll(data)
    paretoSeries.data.setAll(data)

    chart.appear()
    rootRef.current = root
    return () => {
      root.dispose()
    }
  }, [data]
  )

  return (
    <>
      <div style={{ display: 'flex' }} id={chartId + McPageHelpers.FOR_PDF_ID}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-90deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitle}</p>
        </div>

        <div
          id={chartId}
          style={{
            width: '90%',
            height: '400px',
            cursor: 'pointer',
            flex: '1 100%'
          }}
        >
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-270deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitleSecond}</p>
        </div>
      </div>
    </>
  )
}

export default MultiValueLineAndBarChart
