import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { TabContent, TabPane, Row, Col } from 'reactstrap'

import Head from '../layout/head/Head'
import Content from '../layout/content/Content'
import { useTranslation } from 'react-i18next'
import { useDSsummaryTablesData } from '../store/dataStore'
import CONSTANTS from '../utils/Constants'
import McPageHelpers from './mc-components/McPageHelpers'
import { MarketCalculusCard } from '../components/preview/Preview'
import { useSDSsearchParams } from '../store/searchDataStore'
import McPageHeader from '../components/page-header/McPageHeader'
import McPdfExport from './mc-components/McPdfExport'
import McSubTab from '../components/sub-tab/McSubTab'
import { OVERVIEW_TAB_TYPE } from './mc-components/market-overview/MarketOverviewTable/MarketOverviewTable.settings'
import ChangeSection from './mc-components/summary-tables/change/ChangeSection'
import { usePDFselectedComponentIds } from '../store/pdfStore'
import SummarySection from './mc-components/summary-tables/SummarySection'
import { useRAWcomtradeSummary } from '../store/rawStore'
import { useEndpointStatusData } from '../store/endpointStatus'
import SummaryDateControl from './mc-components/summaryDateControl/SummaryDateControl'

import SummaryViewSearchHandler from '../components/summaryViewSearchHandler/SummaryViewSearchHandler'

const SummaryView = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const [activeAltTab, setActiveAltTab] = useState(OVERVIEW_TAB_TYPE.WORLD)

  const comtradeSummaryEndpointStatus = useEndpointStatusData.comtradeSummary()

  const comtradeSummary = useRAWcomtradeSummary()

  const summaryTablesData = useDSsummaryTablesData()

  const searchParams = useSDSsearchParams()
  const summaryParams = searchParams?.summary

  const isMonthly = {
    importer: summaryParams?.importer?.yearly === 'false',
    exporter: summaryParams?.exporter?.yearly === 'false',
    world: summaryParams?.world?.importer?.yearly === 'false',
    change: summaryParams?.change?.yearly === 'false'
  }

  const selectedComponentIds = usePDFselectedComponentIds()

  const tableImportYearRef = useRef(null)
  const tableImportMonthRef = useRef(null)
  const tableExportYearRef = useRef(null)
  const tableExportMonthRef = useRef(null)
  const searchHeader = searchParams.hsCode + ' - ' + McPageHelpers.findCountryName(searchParams.countryToExport) + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)
  const tableWorldImportYearRef = useRef(null)
  const tableWorldImportMonthRef = useRef(null)
  const tableWorldExportYearRef = useRef(null)
  const tableWorldExportMonthRef = useRef(null)
  const changeTablesRef = useRef(null)
  const subtitle = searchParams.hsCode

  const pdfDownload = (onlyContent = false) => {
    const tableImportYear = tableImportYearRef?.current?.generateSummaryTablePdf('tableImportYear')
    const tableImportMonth = tableImportMonthRef?.current?.generateSummaryTablePdf('tableImportMonth')
    const tableExportYear = tableExportYearRef?.current?.generateSummaryTablePdf('tableExportYear')
    const tableExportMonth = tableExportMonthRef?.current?.generateSummaryTablePdf('tableExportMonth')
    const tableWorldImportYear = tableWorldImportYearRef?.current?.generateSummaryTablePdf('tableWorldImportYear')
    const tableWorldImportMonth = tableWorldImportMonthRef?.current?.generateSummaryTablePdf('tableWorldImportMonth')
    const tableWorldExportYear = tableWorldExportYearRef?.current?.generateSummaryTablePdf('tableWorldExportYear')
    const tableWorldExportMonth = tableWorldExportMonthRef?.current?.generateSummaryTablePdf('tableWorldExportMonth')
    const changeTables = changeTablesRef?.current?.generateTablesPdf()

    return McPdfExport.SummaryTables(
      onlyContent,
      searchHeader,
      subtitle,
      selectedComponentIds,
      changeTables,
      isMonthly,
      tableImportYear,
      tableImportMonth,
      tableExportYear,
      tableExportMonth,
      tableWorldImportYear,
      tableWorldImportMonth,
      tableWorldExportYear,
      tableWorldExportMonth
    )
  }

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true).then(content => content)
      return content
    }
  }))

  const summaryViewSearchHandlerRef = useRef()

  return (
    <>
      <Head title={t('menu.summary_tables')}/>
      <Content>
        <McPageHeader title={t('menu.summary_tables')}/>
        {searchParams.countryToExport && searchParams.yourCountry &&
          <MarketCalculusCard className={'mb-4'}>
            <Row style={{ justifyContent: 'center' }}>
              <Col xs='0' lg='4'>{/* SPACE */}</Col>

              <Col xs='12' lg='4' style={{ alignContent: 'center', justifyContent: 'end' }}>
                <McSubTab
                  activeTab={activeAltTab}
                  setActiveTab={setActiveAltTab}
                />
              </Col>

              <Col xs='12' sm='8' md='6' lg='4' className='mt-1 mt-lg-0'>
                <SummaryDateControl
                  summaryTablesData={summaryTablesData}
                  summaryParams={summaryParams}
                  comtradeSummaryEndpointStatus={comtradeSummaryEndpointStatus}
                  searchParams={searchParams}
                  monthlyText={t('monthly')}
                  yearlyText={t('yearly')}
                  activeAltTab={activeAltTab}
                />
              </Col>
            </Row>
          </MarketCalculusCard>
        }
        <TabContent activeTab={activeAltTab}>
            <TabPane tabId={OVERVIEW_TAB_TYPE.SOURCE}>
              {isMonthly.importer
                ? <MarketCalculusCard className={!comtradeSummary?.importerMonthly?.length ? 'loading-div' : ''}>
                    <SummarySection
                      ref={tableImportMonthRef}
                      data={summaryTablesData?.import?.monthly}
                      date={summaryTablesData?.import?.month}
                      titleKey={'st_month_importer'}
                      graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_2.replace('T-', 'H-')}
                      code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_2}
                      isWorld={false}
                      isYearly={false}
                      isImport={true}
                      explanation={summaryTablesData?.import?.monthlyExplanation}
                      onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                    />
                  </MarketCalculusCard>
                : <MarketCalculusCard className={!comtradeSummary?.importerYearly?.length ? 'loading-div' : ''}>
                    <SummarySection
                      ref={tableImportYearRef}
                      data={summaryTablesData?.import?.yearly}
                      date={summaryTablesData?.import?.year}
                      titleKey={'st_year_importer'}
                      graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_1.replace('T-', 'H-')}
                      code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_1}
                      isWorld={false}
                      isYearly={true}
                      isImport={true}
                      explanation={summaryTablesData?.import?.yearlyExplanation}
                      onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                    />
                  </MarketCalculusCard>
              }
            </TabPane>
            <TabPane tabId={OVERVIEW_TAB_TYPE.DESTINATION}>
              {isMonthly.exporter
                ? <MarketCalculusCard className={!comtradeSummary?.exporterMonthly?.length ? 'loading-div' : ''}>
                    <SummarySection
                      ref={tableExportMonthRef}
                      data={summaryTablesData?.export.monthly}
                      date={summaryTablesData?.export.month}
                      titleKey={'st_month_exporter'}
                      graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_4.replace('T-', 'H-')}
                      code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_4}
                      isWorld={false}
                      isYearly={false}
                      isImport={false}
                      explanation={summaryTablesData?.export.monthlyExplanation}
                      onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                    />
                  </MarketCalculusCard>
                : <MarketCalculusCard className={!comtradeSummary?.exporterYearly?.length ? 'loading-div' : ''}>
                    <SummarySection
                      ref={tableExportYearRef}
                      data={summaryTablesData?.export.yearly}
                      date={summaryTablesData?.export.year}
                      titleKey={'st_year_exporter'}
                      graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_3.replace('T-', 'H-')}
                      code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_3}
                      isWorld={false}
                      isYearly={true}
                      isImport={false}
                      explanation={summaryTablesData?.export.yearlyExplanation}
                      onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                    />
                  </MarketCalculusCard>
              }
            </TabPane>
            <TabPane tabId={OVERVIEW_TAB_TYPE.WORLD}>
              {isMonthly.world
                ? <>
                    <MarketCalculusCard className={!comtradeSummary?.worldImportMonthly?.length ? 'loading-div' : ''}>
                      <SummarySection
                        ref={tableWorldImportMonthRef}
                        data={summaryTablesData?.world.import.monthly}
                        date={summaryTablesData?.world.import.month}
                        titleKey={'st_world_month_importer'}
                        graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_6.replace('T-', 'H-')}
                        code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_6}
                        isWorld={true}
                        isYearly={false}
                        isImport={true}
                        explanation={summaryTablesData?.world.import.monthlyExplanation}
                        onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                      />
                    </MarketCalculusCard>
                    <MarketCalculusCard className={!comtradeSummary?.worldExportMonthly?.length ? 'loading-div' : ''}>
                      <SummarySection
                        ref={tableWorldExportMonthRef}
                        data={summaryTablesData?.world.export.monthly}
                        date={summaryTablesData?.world.export.month}
                        titleKey={'st_world_month_exporter'}
                        graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_8.replace('T-', 'H-')}
                        code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_8}
                        isWorld={true}
                        isYearly={false}
                        isImport={false}
                        explanation={summaryTablesData?.world.export.monthlyExplanation}
                        onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                      />
                    </MarketCalculusCard>
                  </>
                : <>
                    <MarketCalculusCard className={!comtradeSummary?.worldImportYearly?.length ? 'loading-div' : ''}>
                      <SummarySection
                        ref={tableWorldImportYearRef}
                        data={summaryTablesData?.world.import.yearly}
                        date={summaryTablesData?.world.import.year}
                        titleKey={'st_world_year_importer'}
                        graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_5.replace('T-', 'H-')}
                        code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_5}
                        isWorld={true}
                        isYearly={true}
                        isImport={true}
                        explanation={summaryTablesData?.world.import.yearlyExplanation}
                        onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                      />
                    </MarketCalculusCard>
                    <MarketCalculusCard className={!comtradeSummary?.worldExportYearly?.length ? 'loading-div' : ''}>
                      <SummarySection
                        ref={tableWorldExportYearRef}
                        data={summaryTablesData?.world.export.yearly}
                        date={summaryTablesData?.world.export.year}
                        titleKey={'st_world_year_exporter'}
                        graphCode={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_7.replace('T-', 'H-')}
                        code={CONSTANTS.BADGE_CODES.SUMMARY_TABLES_TABLE_7}
                        isWorld={true}
                        isYearly={true}
                        isImport={false}
                        explanation={summaryTablesData?.world.export.yearlyExplanation}
                        onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
                      />
                    </MarketCalculusCard>
                  </>
              }
            </TabPane>
            <TabPane tabId={OVERVIEW_TAB_TYPE.CHANGE}>
              <ChangeSection
                ref={changeTablesRef}
                data={comtradeSummary}
                isMonthly={isMonthly.change}
                isPdf={props?.isPdf}
                onTableRowClick={summaryViewSearchHandlerRef?.current?.onTableRowClick}
              />
            </TabPane>
        </TabContent>
      </Content>

      <SummaryViewSearchHandler ref={summaryViewSearchHandlerRef} />
    </>
  )
})

export default SummaryView
