import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { useTranslation } from 'react-i18next'

import ErrorModal from '../error-modal/error-modal'

import { useEndpointStatusData, useEndpointStatusActions } from '../../store/endpointStatus'
import { useGlobalStoreData } from '../../store/globalStore'
import { useSDSsearchParams } from '../../store/searchDataStore'

import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { HOMEPAGE_URL } from '../../services/api-common'
import { LANGUAGES } from '../../utils/Utils'

const showErrorModal = (fireErrorModal, errorMessage, t, searchParams, allowedCountries, languageGSD) => {
  switch (errorMessage?.ruleType) {
    case 'HS_CODE_COUNT':
      fireErrorModal(
        t('error_hs_code_count_limit_text', { limit: errorMessage.limit }),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'HS_CODE_SET':
      fireErrorModal(
        t('error_hs_code_set_text', { hsCode: searchParams.hsCode }),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'SEARCH_COUNTRY_SET':
      fireErrorModal(
        t('error_search_country_set_text', { exporter: McPageHelpers.findCountryName(searchParams.countryToExport) }),
        allowedCountries && allowedCountries.map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'MARKET_REPORT_TOTAL_SEARCH_COUNT':
      fireErrorModal(
        t('error_market_report_total_search_limit_title', { limit: errorMessage.limit }),
        t('error_market_report_total_search_limit_text', { currentValue: errorMessage.currentValue })
      )
      break
    case 'MARKET_REPORT_DAILY_SEARCH_COUNT':
      fireErrorModal(
        t('error_daily_search_limit_title', { limit: Math.round(errorMessage.limit / 10) }),
        t('error_daily_search_limit_text', { currentValue: Math.round(errorMessage.currentValue / 10) })
      )
      break
    case 'MARKET_REPORT_ANONYMOUS_DAILY_SEARCH_COUNT':
      fireErrorModal(
        t('error_anonymous_daily_search_limit_title', { limit: Math.round(errorMessage.limit / 10) }),
        t('error_anonymous_daily_search_limit_text', { currentValue: Math.round(errorMessage.currentValue / 10) }),
        `${HOMEPAGE_URL}/${languageGSD === LANGUAGES.TURKISH ? 'tr/uyeol' : 'en/register'}/`
      )
      break
    case 'MARKET_REPORT_EXCEL_DOWNLOAD_COUNT':
      fireErrorModal(
        t('error_excel_download_count_limit_title', { limit: errorMessage.limit }),
        t('error_excel_download_count_limit_text', { currentValue: errorMessage.currentValue })
      )
      break
  }
}

const RuleErrorModalHandler = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const userGSD = useGlobalStoreData.user()
  const languageGSD = useGlobalStoreData.language()

  const searchParams = useSDSsearchParams()

  const errorMessage = useEndpointStatusData.errorMessage()
  const { setEndpointStatus } = useEndpointStatusActions()

  const searchCountrySet = userGSD?.rules?.find(item => item.type === 'SEARCH_COUNTRY_SET')
  const allowedCountries = searchCountrySet?.parameters?.allowedSearchCountries

  const [modalFail, setModalFail] = useState(false)
  const [errorModalMessage, setErrorModalMessage] = useState()
  const [errorModalTitle, setErrorModalTitle] = useState()
  const [errorModalHref, setErrorModalHref] = useState(null)

  const fireErrorModal = (title, message, href = null) => {
    setErrorModalHref(href)
    setErrorModalTitle(title)
    setErrorModalMessage(message)
    setModalFail(true)
  }

  useImperativeHandle(ref, () => ({
    fireErrorModal
  }))

  useEffect(() => {
    showErrorModal(
      fireErrorModal,
      errorMessage,
      t,
      searchParams,
      allowedCountries,
      languageGSD
    )
  }, [errorMessage])

  return (
    <ErrorModal
      isOpen={modalFail}
      toggleModalFail={() => setModalFail((prevState) => !prevState)}
      title={errorModalTitle}
      message={errorModalMessage}
      linkText={errorModalHref ? t('error_anonymous_daily_search_linkText') : null}
      href={errorModalHref}
      clearErrorState={() => { setEndpointStatus('errorMessage') }}
    />
  )
})

export default RuleErrorModalHandler
