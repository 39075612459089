import React from 'react'
import { Icon } from '../Component'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../utils/Utils'
import { HOMEPAGE_URL } from '../../services/api-common'
import { useGlobalStoreData } from '../../store/globalStore'
import BaseModal from '../baseModal/BaseModal'

const ErrorModal = (props) => {
  const {
    isOpen,
    toggleModalFail,
    title,
    message,
    linkText,
    href,
    clearErrorState = null,
    icon = null
  } = props

  const { t } = useTranslation()
  const languageGSD = useGlobalStoreData.language()

  const getContactPage = () => {
    if (languageGSD === LANGUAGES.TURKISH) { return `${HOMEPAGE_URL + '/' + languageGSD}/iletisim` }
    return `${HOMEPAGE_URL + '/' + languageGSD}/contact`
  }

  return (
    <BaseModal
      isOpen={isOpen}
      toggleModal={() => {
        toggleModalFail()
        clearErrorState && clearErrorState()
      }}
      title={title}
      message={message}
      icon={ icon || <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>}
      linkText={linkText || t('please_contact_us')}
      href={href || getContactPage()}
    />
  )
}

export default ErrorModal
