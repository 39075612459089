import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

import McPageHelpers, { findCountry } from '../../pages/mc-components/McPageHelpers'
import { useGlobalStoreData } from '../../store/globalStore'

import { McHeatMap } from '../Component'
import CompactSummaryTable from './CompactSummaryTable'

const ListMapContent = (props) => {
  const {
    data,
    isYearly,
    onClick,
    loading,
    isTableSkeleton
  } = props

  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const initState = {
    table: [],
    graph: []
  }

  const [state, setState] = useState(initState)

  useEffect(() => {
    if (data?.length) {
      const graphData = data?.filter(country => country.partnerCode !== 0).reverse().map(item => {
        const countryId = countriesGSD.filter(country => country.countryId === item.partnerCode).map(item => item.countryCode)
        if (countryId[0] === 'UK') {
          countryId[0] = 'GB'
        } else if (countryId[0] === 'KV') {
          countryId[0] = 'XK'
        }
        return ({
          countryName: McPageHelpers.findCountryName(item.partnerCode),
          formatValue: McPageHelpers.numToLocaleString(Math.round(McPageHelpers.numDivisionByThousand(item.totalValue))),
          value: McPageHelpers.numDivisionByThousand(item.totalValue),
          id: countryId,
          tooltipTxt: McPageHelpers.findCountryName(item.partnerCode) + ':\n' + t('value_with_dollar_t') + ': ' + McPageHelpers.numToLocaleString(Math.round(McPageHelpers.numDivisionByThousand(item.totalValue)))
        })
      })

      setState({ table: data, graph: graphData })
    } else {
      setState(initState)
    }
  }, [data])

  return (
    <div style={{ position: 'relative' }}>
      {loading &&
        <>
          <div style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 4,
            backgroundColor: '#000000',
            opacity: 0.1
          }}>
          </div>
          <div
            className="spinner-border"
            style={{
              width: 40,
              height: 40,
              fontSize: 28,
              margin: 0,
              position: 'absolute',
              top: 'calc(50% - 20px)',
              left: 'calc(25% - 20px)',
              zIndex: 5
            }}
          />
          <div
            className="spinner-border"
            style={{
              width: 40,
              height: 40,
              fontSize: 28,
              margin: 0,
              position: 'absolute',
              top: 'calc(50% - 20px)',
              right: 'calc(25% - 20px)',
              zIndex: 5
            }}
          />
        </>
      }

      <Row>
        <Col lg='6'>
          <McHeatMap
            chartId='listMapId'
            chartData={state.graph}
            height={300}
            onClick={(e) => {
              if (typeof e.target.dataItem.dataContext.id === 'object') {
                onClick(e.target.dataItem.dataContext.id?.[0])
              } else {
                onClick(null)
              }
            }}
          />
        </Col>
        <Col lg='6'>
          <CompactSummaryTable
            data={state.table}
            loading={loading}
            isYearly={isYearly}
            isTableSkeleton={isTableSkeleton}
            onClick={(row, e) => {
              const country = findCountry(row?.partnerCode)

              if (e?.target?.innerText === country?.countryName) { // click country name cell
                if (country?.countryCode) {
                  onClick(country.countryCode)
                }
              }
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ListMapContent
