import { UserTypes } from './constants'

export const getBooleanUserRolesByUser = (user) => {
  const userRoles = user?.roles

  const B2B_PERSONNEL = !!userRoles?.includes(UserTypes.B2B_PERSONNEL)
  const B2B_USER = !!userRoles?.includes(UserTypes.B2B_USER)
  const USER = !!userRoles?.includes(UserTypes.USER)
  const ADMIN = !!userRoles?.includes(UserTypes.ADMIN)
  const ANONYMOUS = !!userRoles?.includes(UserTypes.ANONYMOUS)

  return {
    B2B_PERSONNEL,
    B2B_USER,
    USER,
    ADMIN,
    ANONYMOUS,
    // imaginal types:
    B2B: B2B_PERSONNEL || B2B_USER,
    B2B_ONLY: (B2B_PERSONNEL || B2B_USER) && (!USER && !ADMIN && !ANONYMOUS)
  }
}

export const extractHtmlContent = (htmlString) => {
  return new DOMParser()
    .parseFromString(htmlString, 'text/html')
    .documentElement.textContent
}
