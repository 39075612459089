import { create } from 'zustand'
import { findCountryName } from '../pages/mc-components/McPageHelpers'
import menu from '../layout/menu/MenuData'
import urlServices from '../services/urlServices'

const urlSearchParams = new URL(window.location.href).searchParams.toString()

const useSearchDataStore = create((set) => (
  {
    searchParams: {
      hsCode: null,
      countryToExport: null,
      yourCountry: null,
      isInclude: null,
      capacity: null,
      minAmount: null,
      rangeCenter: null,
      dataCoverage: null,
      allowedCountry: null,
      productDesc: null,
      summary: {
        importer: {
          year: null,
          yearMonth: null,
          yearly: 'true'
        },
        exporter: {
          year: null,
          yearMonth: null,
          yearly: 'true'
        },
        world: {
          importer: {
            year: null,
            yearMonth: null,
            yearly: 'true'
          },
          exporter: {
            year: null,
            yearMonth: null,
            yearly: 'true'
          }
        },
        change: {
          year: null,
          yearMonth: null,
          yearly: 'true'
        }
      }
    },
    selectBoxData: {
      hsCode: [],
      coverageData: [],
      countries: []
    },
    isVisible: urlSearchParams === '',
    searchHeader: '',

    searchTrigger: false,
    searchedDataCoverage: false,

    pushNotifications: [],
    firstMenuItemLink: menu[0].link,

    actions: {
      startSearching: () => set(
        (prevState) => {
          return (
            {
              searchTrigger: true,
              pushNotifications: [],
              searchedDataCoverage: prevState.searchParams.dataCoverage,
              searchHeader: `${prevState.searchParams.hsCode + ' - ' + findCountryName(prevState.searchParams.countryToExport) + ' - ' + findCountryName(prevState.searchParams.yourCountry) + ' - ' + prevState.searchParams.productDesc}`
            }
          )
        }
      ),
      finishSearching: () => set({ searchTrigger: false, pushNotifications: [] }),
      setPushNotification: (notification) => set(
        (prevState) => (
          { pushNotifications: [...prevState.pushNotifications, notification] }
        )
      ),
      setSearchParams: (paramName, data) => set(
        (prevState) => {
          const updatedSearchParams = {
            ...prevState.searchParams,
            [paramName]: data
          }

          const result = {
            searchParams: updatedSearchParams
          }

          return result
        }
      ),
      setSearchParamsWithPrev: (changedObj) => set(
        (prevState) => {
          const updatedSearchParams = {
            ...prevState.searchParams,
            ...changedObj
          }

          const result = {
            searchParams: updatedSearchParams
          }

          return result
        }
      ),
      setUrlParamsRelatedSearchParams: (
        hsCode,
        countryToExport,
        yourCountry,
        productDesc,
        summary
      ) => set(
        (prevState) => {
          const updatedSearchParams = {
            ...prevState.searchParams,
            hsCode,
            countryToExport,
            yourCountry,
            productDesc,
            summary
          }

          const result = {
            searchParams: updatedSearchParams
          }

          return result
        }
      ),
      setSelectBoxData: (paramName, data) => set(
        (prevState) => (
          {
            selectBoxData: {
              ...prevState.selectBoxData,
              [paramName]: data
            }
          }
        )
      ),
      toggleVisibility: () => set((prevState) => ({ isVisible: !prevState.isVisible })),
      setSearchedDataCoverage: (searchedDataCoverage) => set({ searchedDataCoverage })
    }
  }
))

export const getPayloadBySearchParams = (searchParams) => ({
  hsCode: searchParams.hsCode,
  importerCountryCode: searchParams.countryToExport,
  currentCountryCode: searchParams.yourCountry,
  productDesc: searchParams.productDesc,
  channelId: new Date().getTime(),

  importerYear: searchParams.summary?.importer?.year,
  importerYearMonth: searchParams.summary?.importer?.yearMonth,

  exporterYear: searchParams.summary?.exporter?.year,
  exporterYearMonth: searchParams.summary?.exporter?.yearMonth,

  worldImporterYear: searchParams.summary?.world?.importer?.year,
  worldImporterYearMonth: searchParams.summary?.world?.importer?.yearMonth,

  worldExporterYear: searchParams.summary?.world?.exporter?.year,
  worldExporterYearMonth: searchParams.summary?.world?.exporter?.yearMonth,

  changeYear: searchParams.summary?.change?.year,
  changeYearMonth: searchParams.summary?.change?.yearMonth
})

export const useSDSsearchParams = () => useSearchDataStore((state) => state.searchParams)
export const useSDSselectBoxData = () => useSearchDataStore((state) => state.selectBoxData)
export const useSDSisVisible = () => useSearchDataStore((state) => state.isVisible)
export const useSDSsearchHeader = () => useSearchDataStore((state) => state.searchHeader)
export const useSDSsearchTrigger = () => useSearchDataStore((state) => state.searchTrigger)
export const useSDSsearchedDataCoverage = () => useSearchDataStore((state) => state.searchedDataCoverage)
export const useSDSpushNotifications = () => useSearchDataStore((state) => state.pushNotifications)
export const useSDSfirstMenuItemLink = () => useSearchDataStore((state) => state.firstMenuItemLink)

export const useSDSimaginalPayload = () => useSearchDataStore((state) => getPayloadBySearchParams(state.searchParams))
export const useSDSimaginalUrlSearchParams = () => useSearchDataStore((state) => urlServices.getUrlParamsByObj(state.searchParams))

export const useSearchDataStoreActions = () => useSearchDataStore((state) => state.actions)
