import React from 'react'

import { Row, Col, ButtonGroup, Button } from 'reactstrap'

import ButtonDatePicker from '../datePicker/ButtonDatePicker'

const getMaxDate = (isMonthly) => {
  const maxDate = new Date()
  if (isMonthly) {
    maxDate.setDate(0)
  } else {
    maxDate.setFullYear(maxDate.getFullYear() - 1)
  }

  return maxDate
}

const ListMapDateControl = (props) => {
  const {
    selectedDates,
    monthlyText,
    yearlyText,
    loading,
    setIsMonthly,
    isMonthly,
    onChange
  } = props

  const getSelectedDate = () => {
    if (selectedDates) {
      return isMonthly
        ? new Date(selectedDates.worldImporterYearMonth.toString().substring(0, 4), (parseInt(selectedDates.worldImporterYearMonth) - 1/* -1 for backend dates */).toString().substring(4))
        : new Date(selectedDates.worldImporterYear.toString())
    } else {
      return null
    }
  }

  const selectedDate = getSelectedDate()

  return (
    <Row style={{ justifyContent: 'end' }} className='pb-1'>
      <Col xs='6' lg='3' style={{ alignContent: 'center' }}>
        {selectedDate &&
          <ButtonDatePicker
            selected={selectedDate}
            onChange={(date) => onChange(date, isMonthly)}
            dateFormat={isMonthly ? 'MM/yyyy' : 'yyyy'}
            showMonthYearPicker={isMonthly}
            showFullMonthYearPicker={isMonthly}
            showYearPicker={!isMonthly}
            maxDate={getMaxDate(isMonthly)}
            minDate={new Date('2017')}
            loading={loading}
            buttonHeight={20}
          />
        }
      </Col>
      <Col xs='6' lg='3' style={{ alignContent: 'center' }}>
        <ButtonGroup
          className="btn-block"
          style={{
            height: 20
          }}
        >
          <Button
            size='sm'
            color="primary"
            className="btn-dim"
            onClick={() => { setIsMonthly(false) }}
            outline={isMonthly}
            >{yearlyText}</Button>
          <Button
            size='sm'
            color="primary"
            className="btn-dim"
            onClick={() => { setIsMonthly(true) }}
            outline={!isMonthly}
            >{monthlyText}</Button>
        </ButtonGroup>
      </Col>
    </Row>
  )
}

export default ListMapDateControl
