import api from './api-common'
import { setEndpointStatusOutside } from '../store/endpointStatus'
import { REQUEST_STATUSES } from '../pages/mc-components/McPageHelpers'

export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const ENDPOINTS = {
  COMTRADE_MARKET_OVERVIEW: 'comtrade-market-overview',
  COMTRADE_MARKET_CHANGE: 'comtrade-market-change',
  MARKET_CHANGE: 'market-change',
  RF_TABLE: 'rf-table',
  SP_TABLE: 'sp-table',
  SP_GRAPH: 'sp-graph',
  SP_SUMMARY: 'sp-summary',
  MONTHLY: 'monthly',
  COMTRADE_SUMMARY: 'comtrade-summary',
  DUTIES: 'duties',
  EXCEL: 'excel'
}

export const RESPONSE_OBJ_NAMES = {
  COMTRADE_MARKET_OVERVIEW: 'comtradeMarketOverview',
  COMTRADE_MARKET_CHANGE: 'comtradeMarketChange',
  MARKET_CHANGE: 'marketChanges',
  RF_TABLE: 'rfTable',
  SP_TABLE: 'spTable',
  SP_GRAPH: 'spGraph',
  SP_SUMMARY: 'spSummary',
  MONTHLY: 'monthly',
  COMTRADE_SUMMARY: 'comtradeSummary',
  DUTIES: 'duties'
}

const toCamelCase = (input) => {
  return input.replace(/[-_](.)/g, (match, group1) => {
    return group1.toUpperCase()
  })
}

const login = async (payload, token) => {
  const url = '/auth/login?h-captcha-response=' + token
  try {
    const response = await api.post(url, payload)
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const register = async (payload, token) => {
  // const url = '/users/register?g-recaptcha-response=' + token
  const url = '/users/register?h-captcha-response=' + token
  try {
    const response = await api.post(url, payload)
    return response.status === 200 ? response : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error.response.data)
  }
}

const logout = async () => {
  try {
    const response = await api.get('/auth/logout')
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const checkToken = async () => {
  try {
    const response = await api.get('/auth/check-token')
    return response.status === 200 ? response.data : false
  } catch (error) {
    return false
  }
}

const getUser = async (payload) => {
  try {
    const response = await api.get(`/users/${payload}`)
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    if (error.response?.status === 403 && error.response.data.status === RESPONSE_STATUS.ERROR) {
      return Promise.reject(error.response.data)
    } else return Promise.reject(error)
  }
}

const getHsCodes = async () => {
  try {
    const response = await api.get('/hs-code')
    return response.status === 200 ? response.data.data.hs_codes : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getCountries = async (payload = { search_countries: 'true', user_countries: 'true' }) => {
  try {
    const response = await api.post('/country/ai', payload)
    return response.status === 200 ? response.data.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getAllCountries = async () => {
  try {
    const response = await api.get('/country/comtrade')
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getVersion = async () => {
  try {
    const response = await api.get('/version')
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const sendResetPasswordMail = async (email) => {
  try {
    await api.post('/auth/forgot-password', { email })
  } catch (error) {
    return Promise.reject(error)
  }
}

const getResetTokenValidity = async (token) => {
  try {
    const response = await api.get(`/auth/check-reset-password-token/${token}`)
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const resetPassword = async (payload) => {
  try {
    const response = await api.post('/auth/reset-password', payload)
    return response.status === 200 ? response.data : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getExcel = async (params, endpoint, excelName) => {
  setEndpointStatusOutside('errorMessage')

  document.body.classList.add('exporting')
  params.setIsExcel(true)
  try {
    const response = await api.get(ENDPOINTS.EXCEL + endpoint, {
      responseType: 'blob',
      params: {
        ...params,
        hsCodes: params.hsCode,
        productName: params.productDesc
      }
    })

    if (response.status === 200) {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = excelName + ' ' + params.hsCode + '_' + params.importerCountryCode + '_' + params.currentCountryCode + '.xlsx'
      link.click()
      document.body.classList.remove('exporting')
      params.setIsExcel(false)
    } else {
      document.body.classList.remove('exporting')
      params.setIsExcel(false)
      setEndpointStatusOutside('errorMessage', response.data.detail)
      return Promise.reject(response)
    }
  } catch (error) {
    const responseObj = await error.response.data.text()
    document.body.classList.remove('exporting')
    params.setIsExcel(false)
    setEndpointStatusOutside('errorMessage', JSON.parse(responseObj)?.detail)
    return Promise.reject(error)
  }
}

const getRawData = async (params, endpoint) => {
  setEndpointStatusOutside('errorMessage')
  const endpointState = toCamelCase(endpoint)
  try {
    setEndpointStatusOutside(endpointState, REQUEST_STATUSES.REQUESTED)

    const response = await api.get('/market-report/' + endpoint, {
      params: {
        ...params,
        hsCodes: params.hsCode.toString(),
        productName: params.productDesc
      }
    })
    if (response.status === 200) {
      setEndpointStatusOutside(endpointState, REQUEST_STATUSES.SUCCESS)
      return response.data
    } else {
      setEndpointStatusOutside(endpointState, REQUEST_STATUSES.ERROR)
      setEndpointStatusOutside('errorMessage', response.data.detail)
      return Promise.reject(response)
    }
  } catch (error) {
    setEndpointStatusOutside(endpointState, REQUEST_STATUSES.ERROR)
    setEndpointStatusOutside('errorMessage', error.response.data.detail)
    return Promise.reject(error)
  }
}

export const getSearchPanelImporters = async (params) => {
  try {
    const response = await api.get('/market-report/search-params/comtrade-summary/world-import', {
      params: {
        ...params
      }
    })
    if (response.status === 200) {
      return response.data
    } else {
      return Promise.reject(response)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const aiHsSearch = async (params) => {
  try {
    const response = await api.get('/hs-code/query-text', {
      params: {
        ...params
      }
    })
    if (response.status === 200) {
      return response.data
    } else {
      return Promise.reject(response)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

const ComTradeMarketOverview = (params) => getRawData(params, ENDPOINTS.COMTRADE_MARKET_OVERVIEW)
const ComTradeMarketChange = (params) => getRawData(params, ENDPOINTS.COMTRADE_MARKET_CHANGE)
const MarketChange = (params) => getRawData(params, ENDPOINTS.MARKET_CHANGE)
const RfTable = (params) => getRawData(params, ENDPOINTS.RF_TABLE)
const SpTable = (params) => getRawData(params, ENDPOINTS.SP_TABLE)
const SpGraph = (params) => getRawData(params, ENDPOINTS.SP_GRAPH)
const SpSummary = (params) => getRawData(params, ENDPOINTS.SP_SUMMARY)
const Monthly = (params) => getRawData(params, ENDPOINTS.MONTHLY)
const ComTradeSummary = (params) => getRawData(params, ENDPOINTS.COMTRADE_SUMMARY)
const Duties = (params) => getRawData(params, ENDPOINTS.DUTIES)

const McDataServices = {
  login,
  logout,
  checkToken,
  getUser,
  getHsCodes,
  getCountries,
  getAllCountries,
  getVersion,
  register,
  sendResetPasswordMail,
  getResetTokenValidity,
  resetPassword,
  getExcel
}

export const MarketResultServices = {
  ComTradeMarketOverview,
  ComTradeMarketChange,
  ComTradeSummary,
  RfTable,
  SpTable,
  SpGraph,
  SpSummary,
  Monthly,
  Duties,
  MarketChange
}

export default McDataServices
