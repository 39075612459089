import React, { forwardRef, useEffect, useState } from 'react'
import { McHeatMap } from '../../../components/Component'
import McPageHelpers from '../McPageHelpers'
import SummaryTable from './SummaryTable'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../../store/globalStore'

const SummmarySection = forwardRef((props, ref) => {
  const {
    data,
    date,
    titleKey,
    code,
    isWorld,
    isYearly,
    isImport,
    explanation,
    graphCode,
    onTableRowClick
  } = props

  const yearAndMonth = McPageHelpers.getYearAndMonth(date, isYearly ? McPageHelpers.KEY.YEARLY : McPageHelpers.KEY.MONTHLY)
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const initState = {
    table: [],
    graph: []
  }

  const [state, setState] = useState(initState)

  useEffect(() => {
    if (data?.length) {
      const graphData = data?.filter(country => country.partnerCode !== 0).reverse().map(item => {
        const countryId = countriesGSD.filter(country => country.countryId === item.partnerCode).map(item => item.countryCode)
        if (countryId[0] === 'UK') {
          countryId[0] = 'GB'
        } else if (countryId[0] === 'KV') {
          countryId[0] = 'XK'
        }
        return ({
          countryName: McPageHelpers.findCountryName(item.partnerCode),
          formatValue: McPageHelpers.numToLocaleString(Math.round(McPageHelpers.numDivisionByThousand(item.totalValue))),
          value: McPageHelpers.numDivisionByThousand(item.totalValue),
          id: countryId,
          tooltipTxt: McPageHelpers.findCountryName(item.partnerCode) + ':\n' + t('value_with_dollar_t') + ': ' + McPageHelpers.numToLocaleString(Math.round(McPageHelpers.numDivisionByThousand(item.totalValue)))
        })
      })

      setState({ table: data, graph: graphData })
    } else {
      setState(initState)
    }
  }, [data])

  return (
    <>
      {!!state.graph.length &&
        <McHeatMap
          chartId={graphCode}
          chartData={state.graph}
          code={graphCode}
          height={500}
        />
      }
      <hr/>
      <SummaryTable
        data={state.table}
        title={t(titleKey) + ' ' + yearAndMonth}
        code={code}
        isYearly={isYearly}
        isImport={isImport}
        isWorld={isWorld}
        ref={ref}
        footer={explanation ?? ''}
        onClick={onTableRowClick}
      />
    </>
  )
})

export default SummmarySection
