import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import ErrorModal from '../../../../../components/error-modal/error-modal'

import { useEndpointStatusData, useEndpointStatusActions } from '../../../../../store/endpointStatus'
import McPageHelpers from '../../../../../pages/mc-components/McPageHelpers'

const showErrorModal = (fireErrorModal, errorMessage, t) => {
  switch (errorMessage?.ruleType) {
    case 'B2B_TOTAL_EMAIL_COUNT':
      fireErrorModal(
        t('mailTemplate.ruleTypeErrors.b2b_total_email_count_title', { limit: errorMessage.limit }),
        t('mailTemplate.ruleTypeErrors.b2b_total_email_count_text', { currentValue: errorMessage.currentValue })
      )
      break
    case 'B2B_CAMPAIGN_COUNT':
      fireErrorModal(
        t('mailTemplate.ruleTypeErrors.b2b_campaign_count_title', { limit: errorMessage.limit }),
        t('mailTemplate.ruleTypeErrors.b2b_campaign_count_text', { currentValue: errorMessage.currentValue })
      )
      break
    case 'B2B_HS_CODE_COUNT':
      fireErrorModal(
        t('mailTemplate.ruleTypeErrors.b2b_hs_code_count_title', { limit: errorMessage.limit }),
        t('mailTemplate.ruleTypeErrors.b2b_hs_code_count_text', { currentValue: errorMessage.currentValue })
      )
      break
    case 'B2B_HS_CODE_SET':
      fireErrorModal(
        t('mailTemplate.ruleTypeErrors.b2b_hs_code_set_text'),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
    case 'B2B_SEARCH_COUNTRY_SET':
      fireErrorModal(
        t('mailTemplate.ruleTypeErrors.b2b_search_country_set_title'),
        errorMessage.customMessage.split(',').map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
      break
  }
}

const B2BErrorModalHandler = () => {
  const { t } = useTranslation()

  const errorMessage = useEndpointStatusData.errorMessage()
  const { setEndpointStatus } = useEndpointStatusActions()

  const [modalFail, setModalFail] = useState(false)
  const [errorModalMessage, setErrorModalMessage] = useState()
  const [errorModalTitle, setErrorModalTitle] = useState()
  const [errorModalHref, setErrorModalHref] = useState(null)

  const fireErrorModal = (title, message, href = null) => {
    setErrorModalHref(href)
    setErrorModalTitle(title)
    setErrorModalMessage(message)
    setModalFail(true)
  }

  useEffect(() => {
    showErrorModal(
      fireErrorModal,
      errorMessage,
      t
    )
  }, [errorMessage])

  return (
    <ErrorModal
      isOpen={modalFail}
      toggleModalFail={() => setModalFail((prevState) => !prevState)}
      title={errorModalTitle}
      message={errorModalMessage}
      href={errorModalHref}
      clearErrorState={() => { setEndpointStatus('errorMessage') }}
    />
  )
}

export default B2BErrorModalHandler
