import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'

// Component Imports
import Modal from './Modal'
import MultipleTextInput from '../atoms/MultipleTextInput'
import CustomSelect from '../atoms/CustomSelect'

import { emailRegExp } from '../../../utils/constants'

const emailValidation = (val) => emailRegExp.test(val)

const EditEmailListModal = (props) => {
  const {
    open,
    setOpen,
    emailList,
    setEmailList,
    title
  } = props
  const { t } = useTranslation()

  const [unsavedEmailList, setUnsavedEmailList] = useState([])
  const [seperator, setSeperator] = useState(' ')

  const seperatorOpts = [
    {
      label: t('mailTemplate.wizard.firmList.editEmailListModal.separatorOpts.space'),
      value: ' '
    },
    {
      label: t('mailTemplate.wizard.firmList.editEmailListModal.separatorOpts.star'),
      value: '*'
    },
    {
      label: t('mailTemplate.wizard.firmList.editEmailListModal.separatorOpts.comma'),
      value: ','
    },
    {
      label: t('mailTemplate.wizard.firmList.editEmailListModal.separatorOpts.semicolon'),
      value: ';'
    }
  ]

  useEffect(() => {
    setUnsavedEmailList(emailList?.length ? [...emailList] : [])
  }, [emailList])

  return (
    <Modal
      open={open}
      close={() => {
        setUnsavedEmailList(emailList)
        setOpen(false)
      }}
      submit={() => {
        setEmailList(unsavedEmailList)
        setOpen(false)
      }}
      title={title}
      subTitle={t('mailTemplate.wizard.firmList.editEmailListModal.subTitle')}
      submitText={t('mailTemplate.wizard.firmList.editEmailListModal.submitText')}
      cancelText={t('mailTemplate.wizard.firmList.editEmailListModal.cancelText')}
    >
      <CustomSelect
        fullWidth
        options={seperatorOpts}
        onChange={(e) => {
          setSeperator(e.target.value)
        }}
        labelKey='label'
        valueKey='value'
        label={t('mailTemplate.wizard.firmList.editEmailListModal.separatorLabel')}
        defaultValue=' '
        className='mb-2'
      />
      <MultipleTextInput
        label={t('mailTemplate.wizard.firmList.editEmailListModal.emailListLabel')}
        textFieldProps={{
          sx: {
            '& .MuiInputBase-root': {
              maxHeight: 300,
              overflow: 'hidden',
              overflowY: 'auto !important'
            }
          }
        }}
        value={unsavedEmailList}
        onChange={(e, val) => {
          if (e.target.value === undefined || emailValidation(e.target.value)) {
            setUnsavedEmailList(val)
          } else {
            const valuesWithSpace = e.target.value?.split(seperator)
            if (valuesWithSpace?.length) {
              const validEmailList = valuesWithSpace.filter((item) => emailValidation(item))
              setUnsavedEmailList((prevState) => uniqBy(prevState.concat(validEmailList)))
            }
          }
        }}
      />
    </Modal>
  )
}

export default EditEmailListModal
