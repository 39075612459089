import React, { useState, useRef } from 'react'

import classNames from 'classnames'

import Toggle from '../sidebar/Toggle'
import Logo from '../logo/Logo'
import Menu from '../menu/Menu'
import User from './dropdown/user/User'
import MobileMenu from '../menu/MobileMenu'
import menu from '../menu/MenuData'
import LanguageHead from './dropdown/language/Language'
import RuleErrorModalHandler from '../../components/ruleErrorModalHandler/RuleErrorModalHandler'
import SearchPanel from '../../components/searchPanel/SearchPanel'

import { useTheme, useThemeUpdate } from '../provider/Theme'
import {
  useSDSimaginalPayload,
  useSDSimaginalUrlSearchParams,
  useSDSsearchedDataCoverage
} from '../../store/searchDataStore'
import PdfDropdown from '../../components/dropdown/pdf-dropdown'
import McDataServices from '../../services/McDataServices'
import { usePdfStoreActions, usePDFexcelStatus } from '../../store/pdfStore'
import { useTranslation } from 'react-i18next'
import { useDSglobalParams } from '../../store/dataStore'
import BaseModal from '../../components/baseModal/BaseModal'
import { Icon } from '../../components/Component'
import { useGlobalStoreData } from '../../store/globalStore'
import { HOMEPAGE_URL } from '../../services/api-common'
import { LANGUAGES } from '../../utils/Utils'
import { getBooleanUserRolesByUser } from '../../mailTemplate/src/utils/helpers'

const Header = ({ fixed, className, ...props }) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const themeUpdate = useThemeUpdate()

  const ruleErrorModalRef = useRef()

  const { setIsExcel } = usePdfStoreActions()
  const globalParams = useDSglobalParams()

  const userGSD = useGlobalStoreData.user()
  const languageGSD = useGlobalStoreData.language()
  const isAnonymous = userGSD?.userName === 'ANONYMOUS'

  const userRoles = getBooleanUserRolesByUser(userGSD)

  const payload = useSDSimaginalPayload()
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const searchedDataCoverage = useSDSsearchedDataCoverage()
  const excelStatus = usePDFexcelStatus()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [b2bInfoModalOpen, setB2bInfoModalOpen] = useState(false)

  const registerUrl = `${HOMEPAGE_URL}/${languageGSD === LANGUAGES.TURKISH ? 'tr/uyeol' : 'en/register'}/`

  const headerClass = classNames({
    'nk-header is-regular': true,
    'nk-header-fixed': fixed,
    'is-light': theme.header === 'white',
    [`is-${theme.header}`]: theme.header !== 'white' && theme.header !== 'light',
    [`${className}`]: className
  })
  const comtradeMarketOverviewStatus = excelStatus.comtradeMarketOverview
  const summaryTablesStatus = excelStatus.comtradeSummary
  const importCompaniesStatus = excelStatus.spTable || excelStatus.rfTable
  const priceAnalysisStatus = excelStatus.spTable || excelStatus.monthly
  const customDutiesStatus = excelStatus.duties

  const openB2bInfoModal = userRoles.B2B
    ? null
    : () => { setB2bInfoModalOpen(true) }

  const createExcelSvg = () => {
    let endPoint = ''
    let excelName = ''
    if (window.location.pathname.includes('/market-overview') && comtradeMarketOverviewStatus) {
      endPoint = '/market-overview'
      excelName = t('menu.market_overview')
    } else if (window.location.pathname.includes('/summary-tables') && summaryTablesStatus) {
      endPoint = '/summary-tables'
      excelName = t('menu.summary_tables')
    } else if (window.location.pathname.includes('/importer-companies') && importCompaniesStatus) {
      endPoint = '/importer-companies'
      excelName = t('menu.importer_companies')
    } else if (window.location.pathname.includes('/price-analysis') && priceAnalysisStatus) {
      endPoint = '/price-analysis'
      excelName = t('menu.price_analysis')
    } else if (window.location.pathname.includes('/market-changes') && searchedDataCoverage) {
      endPoint = '/market-changes'
      excelName = t('menu.market_changes')
    } else if (window.location.pathname.includes('/custom-duties') && customDutiesStatus) {
      endPoint = '/custom-duties'
      excelName = t('menu.duties')
    } else {
      return ''
    }

    return (<li>
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#000000"
           className={`svg-icon ${!urlSearchParams ? 'display-none' : ''}`}
           onClick={
            () => isAnonymous
              ? setModalIsOpen(true)
              : McDataServices.getExcel({
                ...payload,
                selectedUnit: globalParams.selectedUnit,
                setIsExcel
              }, endPoint, excelName)
          }
      >

        <g id="SVGRepo_bgCarrier" strokeWidth={0}/>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="SVGRepo_iconCarrier">
          <title>{t('down_xls')}</title>
          <path
            d="M28.781,4.405H18.651V2.018L2,4.588V27.115l16.651,2.868V26.445H28.781A1.162,1.162,0,0,0,30,25.349V5.5A1.162,1.162,0,0,0,28.781,4.405Zm.16,21.126H18.617L18.6,23.642h2.487v-2.2H18.581l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2H28.941Z"
            style={{ fill: '#798bff', fillRule: 'evenodd' }}
          />
          <rect
            x="22.487"
            y="7.439"
            width="4.323"
            height="2.2"
            style={{ fill: '#798bff' }}
          />
          <rect
            x="22.487"
            y="10.94"
            width="4.323"
            height="2.2"
            style={{ fill: '#798bff' }}
          />
          <rect
            x="22.487"
            y="14.441"
            width="4.323"
            height="2.2"
            style={{ fill: '#798bff' }}
          />
          <rect
            x="22.487"
            y="17.942"
            width="4.323"
            height="2.2"
            style={{ fill: '#798bff' }}
          />
          <rect
            x="22.487"
            y="21.443"
            width="4.323"
            height="2.2"
            style={{ fill: '#798bff' }}
          />
          <polygon
            points="6.347 10.673 8.493 10.55 9.842 14.259 11.436 10.397 13.582 10.274 10.976 15.54 13.582 20.819 11.313 20.666 9.781 16.642 8.248 20.513 6.163 20.329 8.585 15.666 6.347 10.673"
            style={{ fill: '#ffffff', fillRule: 'evenodd' }}
          />
        </g>
      </svg>
    </li>)
  }

  return (<div className={headerClass}>
    <div className="container-fluid">
      <div className="nk-header-wrap">
        <div className="nk-menu-trigger me-sm-2 d-lg-none">
          <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility}/>
        </div>
        <div className="nk-menu-trigger">
          <SearchPanel ruleErrorModalRef={ruleErrorModalRef}/>
        </div>
        <div className="nk-header-brand">
          <Logo/>
        </div>
        <div
          className={`nk-header-menu ms-auto ${theme.sidebarMobile ? 'mobile-menu' : ''}  ${theme.sidebarVisibility ? 'nk-header-active' : ''}`}
        >
          <div className="nk-header-mobile">
            <div className="nk-header-brand">
              <Logo/>
            </div>
            <div className="nk-menu-trigger me-n2">
              <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="arrow-left"
                      click={themeUpdate.sidebarVisibility}/>
            </div>
          </div>
          {theme.sidebarMobile
            ? <MobileMenu
                data={menu}
                userRoles={userRoles}
                openB2bInfoModal={openB2bInfoModal}
              />
            : <Menu
                userRoles={userRoles}
                openB2bInfoModal={openB2bInfoModal}
              />
          }
        </div>
        {theme.sidebarVisibility && <div className="nk-header-overlay" onClick={themeUpdate.sidebarVisibility}></div>}
        <div className="nk-header-tools">
          <ul className="nk-quick-nav">
            {createExcelSvg()}
            <li>
              <PdfDropdown onClick={isAnonymous
                ? () => setModalIsOpen(true)
                : null}/>
            </li>
            <li className="language-dropdown d-none d-sm-block me-n1">
              <LanguageHead/>
            </li>
            <li className="user-dropdown">
              <User/>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <BaseModal
      isOpen={b2bInfoModalOpen}
      toggleModal={() => setB2bInfoModalOpen((prevState) => !prevState)}
      title={t('b2b_info_modal_title')}
      message={t('b2b_info_modal_text')}
      icon={<Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-info"></Icon>}
      linkText={t('please_contact_us')}
      href={languageGSD === LANGUAGES.TURKISH
        ? `${HOMEPAGE_URL + '/' + languageGSD}/iletisim`
        : `${HOMEPAGE_URL + '/' + languageGSD}/contact`
      }
    />

    <BaseModal
      isOpen={modalIsOpen}
      toggleModal={() => setModalIsOpen((prevState) => !prevState)}
      title={t('menu.anonymous_download_title')}
      message={t('menu.anonymous_download_message')}
      icon={<Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-warning"></Icon>}
      linkText={t('menu.anonymous_download_linkText')}
      href={registerUrl}
    />

    <RuleErrorModalHandler ref={ruleErrorModalRef}/>
  </div>)
}
export default Header
