import React, { forwardRef, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { enUS, tr } from 'date-fns/locale'
import { Button, Spinner } from 'reactstrap'
import { useGlobalStoreData } from '../../store/globalStore'
import { LOCALE } from '../../utils/Utils'
registerLocale(LOCALE.EN_US, enUS)
registerLocale(LOCALE.TR_TR, tr)

const ButtonDatePicker = (props) => {
  const localeGSD = useGlobalStoreData.locale()

  const {
    onChange,
    selected,
    loading,
    buttonHeight,
    ...restProps
  } = props
  //   const [date, setDate] = useState(new Date())

  //   useEffect(() => {
  //     setDate(selected)
  //   }, selected)

  const ref = useRef()

  return (
    <DatePicker
        // selected={date}
        selected={selected}
        onChange={(newDate) => {
        //   setDate(newDate)
          onChange(newDate)
        }}
        customInput={<CustomInput ref={ref} loading={!!loading} buttonHeight={buttonHeight}/>}
        locale={localeGSD}
        {...restProps}
    />
  )
}

export default ButtonDatePicker

const CustomInput = forwardRef(({ value, onClick, loading, buttonHeight }, ref) => (
  <Button
    color="primary"
    className="btn-dim"
    size='sm'
    onClick={onClick}
    outline={true}
    innerRef={ref}
    style={{
      width: '100%',
      height: buttonHeight || 'unset'
    }}
    disabled={loading}
  >
    <span style={{ paddingRight: 20, paddingLeft: 20 }}>{value}</span>
    {loading &&
      <Spinner
        style={{
          position: 'absolute',
          right: '2%',
          top: 6,
          width: 12,
          height: 12
        }}
        color="primary"
        size="sm"
      />
    }
  </Button>
))
