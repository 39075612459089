import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import McPageHelpers from '../McPageHelpers'
import { McDataTable } from '../../../components/Component'
import { useSDSsearchParams } from '../../../store/searchDataStore'
import MCTitleCard from '../../../components/title-card/MCTitleCard'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { usePDFselectedComponentIds } from '../../../store/pdfStore'

const SummaryTable = forwardRef((props, ref) => {
  const {
    data,
    code,
    isYearly,
    isImport,
    onClick
  } = props

  const { t } = useTranslation()
  const searchParams = useSDSsearchParams()

  const chartSubtitle = !props.isWorld
    ? McPageHelpers.findCountryName(!props.isImport ? searchParams.yourCountry : searchParams.countryToExport) + ' ' + searchParams.hsCode
    : McPageHelpers.findCountryName('World') + ' ' + searchParams.hsCode
  const [hideImportValue, setHideImportValue] = useState(false)
  const [hideExportValue, setHideExportValue] = useState(false)
  const selectedComponentIds = usePDFselectedComponentIds()

  const [showModal, setShowModal] = useState()

  useEffect(() => {
    if (props.isWorld && props.isImport) {
      setHideExportValue(true)
    } else setHideExportValue(false)

    if ((props.isWorld && !props.isImport)) {
      setHideImportValue(true)
    } else setHideImportValue(false)
  }, [hideImportValue, hideExportValue])

  const toggle = () => setShowModal(!showModal)

  const getInfoBox = (e) => {
    e.stopPropagation()
    setShowModal(!showModal)
  }

  const columns = [
    {
      name: ((!props.isWorld && props.isImport)
        ? t('exporter')
        : (props.isWorld && !props.isImport)
            ? t('exporter')
            : (!props.isWorld && !isImport)
                ? t('importer')
                : t('importer')),
      selector: row => McPageHelpers.findCountryName(row.partnerCode),
      conditionalCellStyles: [
        {
          when: row => row.partnerCode !== 0,
          style: {
            color: '#798bff',
            textDecoration: 'underline'
          }
        }
      ],
      left: true,
      sortable: true
    },
    {
      name: (<div>{t('st_pdf_columns.total_value')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalValue / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_pdf_columns.total_weight')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalWeight / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.unit_price')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.unitPrice, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.percent_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.percentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.cumulative_pv_import')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.cumulativePercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.percent_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.percentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.cumulative_pq_import')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.cumulativePercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_export_percent_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldExportPercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_export_percent_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldExportPercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_export_order')}</div>),
      selector: row => row.worldExportOrder === null ? Infinity : row.worldExportOrder,
      format: row => row.worldExportOrder === null ? '-' : row.worldExportOrder,
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_import_order')}</div>),
      selector: row => row.worldImportOrder === null ? Infinity : row.worldImportOrder,
      format: row => row.worldImportOrder === null ? '-' : row.worldImportOrder,
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_import_percent_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldImportPercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true,
      omit: hideExportValue
    },
    {
      name: (<div>{t('st_columns_name.world_import_percent_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldImportPercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true,
      omit: hideExportValue
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_on_year_value') : t('st_columns_name.growth_on_month_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthOneYearValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_three_year_value') : t('st_columns_name.growth_three_month_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthThreeYearValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_one_year_quantity') : t('st_columns_name.growth_one_month_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthOneYearQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_three_year_quantity') : t('st_columns_name.growth_three_month_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthThreeYearQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.xxport_increase')}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row.xXportIncrease), 'decimal', false, 0, 0),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.index_on_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.indexOnValue, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.index_on_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.indexOnQuantity, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.trade_balance')}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row.tradeBalance), 'decimal', false, 0, 0),
      right: true,
      sortable: true
    },
    {
      name: (<div className="info-button">
        {t('st_columns_name.concentration')}
        <div onClick={(e) => getInfoBox(e)} className="info-icon-wrapper">
          <button
            className="btn btn-icon w-50">
            <em className="icon ni ni-info-i st-icon-info"></em>
          </button>
        </div>
      </div>),
      selector: row => McPageHelpers.numToLocaleString(row.concentration, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    }
  ]
  const columnsExport = [
    {
      name: ((!props.isWorld && props.isImport)
        ? t('exporter')
        : (props.isWorld && !props.isImport)
            ? t('exporter')
            : (!props.isWorld && !isImport)
                ? t('importer')
                : t('importer')),
      selector: row => McPageHelpers.findCountryName(row.partnerCode),
      conditionalCellStyles: [
        {
          when: row => row.partnerCode !== 0,
          style: {
            color: '#798bff',
            textDecoration: 'underline'
          }
        }
      ],
      left: true,
      sortable: true
    },
    {
      name: (<div>{t('st_pdf_columns.total_value')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalValue / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_pdf_columns.total_weight')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalWeight / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.unit_price')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.unitPrice, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.percent_value_export')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.percentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.cumulative_pv_export')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.cumulativePercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.percent_quantity_export')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.percentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.cumulative_pq_export')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.cumulativePercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_export_percent_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldExportPercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true,
      omit: hideImportValue
    },
    {
      name: (<div>{t('st_columns_name.world_export_percent_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldExportPercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true,
      omit: hideImportValue
    },
    {
      name: (<div>{t('st_columns_name.world_export_order')}</div>),
      selector: row => row.worldExportOrder === null ? Infinity : row.worldExportOrder,
      format: row => row.worldExportOrder === null ? '-' : row.worldExportOrder,
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_import_order')}</div>),
      selector: row => row.worldImportOrder === null ? Infinity : row.worldImportOrder,
      format: row => row.worldImportOrder === null ? '-' : row.worldImportOrder,
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_import_percent_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldImportPercentValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.world_import_percent_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.worldImportPercentQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_on_year_value') : t('st_columns_name.growth_on_month_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthOneYearValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_three_year_value') : t('st_columns_name.growth_three_month_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthThreeYearValue, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_one_year_quantity') : t('st_columns_name.growth_one_month_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthOneYearQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (
        <div>{isYearly ? t('st_columns_name.growth_three_year_quantity') : t('st_columns_name.growth_three_month_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.growthThreeYearQuantity, 'percent', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.xxport_increase')}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row.xXportIncrease), 'decimal', false, 0, 0),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.index_on_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.indexOnValue, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.index_on_quantity')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.indexOnQuantity, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    },
    {
      name: (<div>{t('st_columns_name.trade_balance')}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row.tradeBalance), 'decimal', false, 0, 0),
      right: true,
      sortable: true
    },
    {
      name: (<div className="info-button">
        {t('st_columns_name.concentration')}
        <div onClick={(e) => getInfoBox(e)} className="info-icon-wrapper">
          <button
            className="btn btn-icon w-50">
            <em className="icon ni ni-info-i st-icon-info"></em>
          </button>
        </div>
      </div>),
      selector: row => McPageHelpers.numToLocaleString(row.concentration, 'decimal', false, 2, 2),
      right: true,
      sortable: true
    }
  ]

  const createColumnsPdf = (props) => {
    const columns = {}
    columns.partnerCode = (!props.isWorld && props.isImport)
      ? t('exporter')
      : (props.isWorld && !props.isImport)
          ? t('exporter')
          : (!props.isWorld && !isImport)
              ? t('importer')
              : t('importer')

    columns.totalValue = t('st_pdf_columns.total_value')
    columns.totalWeight = t('st_pdf_columns.total_weight')
    columns.unitPrice = t('st_columns_name.unit_price_long')

    if (props.isImport) {
      columns.percentValue = t('st_columns_name.percent_value')
      columns.cumulativePercentValue = t('st_columns_name.cumulative_pv_import')
      columns.percentQuantity = t('st_columns_name.percent_quantity')
    } else {
      columns.percentValue = t('st_columns_name.percent_value_export')
      columns.cumulativePercentValue = t('st_columns_name.cumulative_pv_export')
      columns.percentQuantity = t('st_columns_name.percent_quantity_export')
    }

    if (props.isWorld) {
      if (props.isImport) {
        columns.worldExportPercentValue = t('st_columns_name.world_export_percent_value')
        columns.worldExportPercentQuantity = t('st_columns_name.world_export_percent_quantity')
        columns.worldExportOrder = t('st_columns_name.world_export_order')
        columns.worldImportOrder = t('st_columns_name.world_import_order')
      } else {
        columns.worldExportOrder = t('st_columns_name.world_export_order')
        columns.worldImportOrder = t('st_columns_name.world_import_order')
        columns.worldImportPercentValue = t('st_columns_name.world_import_percent_value')
        columns.worldImportPercentQuantity = t('st_columns_name.world_import_percent_quantity')
      }
    } else {
      columns.worldExportPercentValue = t('st_columns_name.world_export_percent_value')
      columns.worldExportPercentQuantity = t('st_columns_name.world_export_percent_quantity')
      columns.worldExportOrder = t('st_columns_name.world_export_order')
      columns.worldImportOrder = t('st_columns_name.world_import_order')
      columns.worldImportPercentValue = t('st_columns_name.world_import_percent_value')
      columns.worldImportPercentQuantity = t('st_columns_name.world_import_percent_quantity')
    }

    return columns
  }

  const columnsPdf = createColumnsPdf(props)

  const tableTitle = chartSubtitle + ' ' + props.title

  useImperativeHandle(ref, () => ({
    generateSummaryTablePdf (id) {
      if (selectedComponentIds.includes(id)) {
        const transformedData = McPageHelpers.getSummaryTableTransformData(data) ?? []
        const result = [
          McPageHelpers.generateTableTitleForPdf(tableTitle, null, null),
          McPageHelpers.generateTableSubTitleForPdf(props.code, null, null),
          McPageHelpers.generatePdfTableFromObjects(transformedData, columnsPdf, true, true)
        ]

        if (Array.isArray(props.footer) && props.footer.length > 0) {
          const footerItems = props.footer.map((item, index) => ({
            text: item,
            fontSize: 10,
            margin: index !== props.footer.length - 1 ? [0, 0, 0, 10] : [0, 0, 0, 20],
            color: 'gray',
            italics: true
          }))

          result.push(...footerItems)
        }

        return result
      } else return null
    }
  }))

  return (
    <>
      {showModal && (
        <Modal isOpen={showModal} toggle={toggle} size={'lg'}>
          <ModalHeader toggle={toggle}>{t('st_columns_name.concentration')}</ModalHeader>
          <ModalBody>
            {t('st_concentration_p1')}
            <h6 className="mt-3 mb-3">{t('st_concentration_title')}</h6>
            <p>{t('st_concentration_p2')}</p>
            <p>{t('st_concentration_ex_p1')}</p>
            <p>{t('st_concentration_ex_p2')}</p>
            <p>{t('st_concentration_ex_p3')}</p>
          </ModalBody>
        </Modal>
      )}

      <MCTitleCard code={props.code} chartSubTitle={chartSubtitle} chartTitle={props.title} codeColor="info"/>
      <div className="bg-white">
        <McDataTable data={data ?? []}
                     columns={isImport ? columns : columnsExport}
                     id={McPageHelpers.SECTION_IDS.SUMMARY_TABLES + code}
                     footer={props.footer}
                     hideHeader
                     pagination
                     onClick={onClick}
                     paginationPerPage={25}
        />
      </div>

    </>

  )
})

export default SummaryTable
