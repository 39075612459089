import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { renderToStaticMarkup } from 'react-dom/server'

import { DataTablePagination } from '../Component'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { useSDSsearchParams } from '../../store/searchDataStore'

const insertStaticHeadRow = (tableHead, staticHeadRowcontainerId, component) => {
  const generated = document.createElement('div')

  generated.setAttribute('id', staticHeadRowcontainerId)
  generated.classList.add('rdt_TableHeadRow')
  generated.style.width = '100%'
  generated.innerHTML = renderToStaticMarkup(component)

  tableHead[0]?.insertBefore(generated, tableHead[0].firstChild)
}

const manageStaticHeadRow = (id, staticHeadRowcontainerId, component) => {
  // generating static custom head row with staticHeadRow component (DOM manipulation)
  const inserted = document.getElementById(staticHeadRowcontainerId)
  const tableHead = document.querySelectorAll(`#${id} div.rdt_TableHead`)

  if (inserted) {
    inserted.remove()
    if (tableHead?.length) {
      insertStaticHeadRow(tableHead, staticHeadRowcontainerId, component)
    }
  } else {
    insertStaticHeadRow(tableHead, staticHeadRowcontainerId, component)
  }
}

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name}/>
  </div>
))

const McDataTable = ({
  staticHeadRowUpdateState,
  staticHeadRowcontainerId,
  staticHeadRow,
  data,
  columns,
  pagination,
  actions,
  classNamePagination,
  className,
  selectableRows,
  uniqueId,
  expandableRows,
  hsCrossQuantityUnit,
  customStyles,
  id,
  expandedComponent,
  responseCountry,
  isSearchWithCountry,
  onRowClicked,
  onClick,
  hideHeader,
  showLastUpdate,
  footer,
  paginationComponentOptions,
  defaultPagination,
  paginationPerPage,
  fixedHeader,
  fixedHeaderScrollHeight
}) => {
  const [tableData, setTableData] = useState(data)
  const [searchText, setSearchText] = useState('')
  const [rowsPerPageS, setRowsPerPage] = useState(paginationPerPage || (id === 'marketOverviewTable' ? 25 : 10))
  const [mobileView, setMobileView] = useState()
  const [currentPageS, setCurrentPage] = useState(1)
  const [currentRow, setCurrentRow] = useState(null)
  const [isRowClick, setIsRowClick] = useState(false)
  const { t } = useTranslation()
  const sectors = t('sectors', { returnObjects: true })
  const searchParams = useSDSsearchParams()

  const onRowMouseEnterF = (id, e) => {
    const card = uniqueId ? document.getElementById('recommendedFirmCard-' + id) : document.getElementById('firmcard' + id)
    if (card) {
      card.style.visibility = 'visible'
      card.style.opacity = '1'
      card.style.left = e.clientX + 'px'
      card.style.top = e.clientY + 'px'
    }
  }

  const onRowMouseLeaveF = (id) => {
    const card = uniqueId ? document.getElementById('recommendedFirmCard-' + id) : document.getElementById('firmcard' + id)
    if (card) {
      card.style.visibility = 'hidden'
      card.style.opacity = '0'
      card.style.transition = 'visibility 0s, opacity 0.1s linear 1s'
    }
  }

  const findSectorWithTranslate = (textSearched, allSectors) => {
    for (const [sector, keywords] of Object.entries(allSectors)) {
      if (keywords.toLowerCase().includes(textSearched.toLowerCase())) {
        return sector
      }
    }
    return undefined
  }

  const conditionalRowStyles = [
    {
      when: row => McPageHelpers.findCountryName(row.partnerCode || row.countryCode || row.country || row.countryId) === McPageHelpers.findCountryName(searchParams.yourCountry),
      classNames: ['highlighted']
    }
  ]

  useEffect(() => {
    setTableData(data)
    setCurrentPage(1)
  }, [data])

  useEffect(() => {
    let defaultData = tableData
    if (searchText !== '') {
      defaultData = data.filter((item) => {
        if (isSearchWithCountry) {
          const countryName = McPageHelpers.findCountryName(item.countryCode, responseCountry).toLocaleLowerCase(localStorage.getItem('locale'))
          return countryName.includes(searchText.toLowerCase())
        } else {
          return item.importerName.toLocaleLowerCase(localStorage.getItem('locale')).includes(searchText) ||
            (item.sector_list && item.sector_list.some(sectorItem =>
              sectorItem.sector === findSectorWithTranslate(searchText, sectors) && sectorItem.sector
            )
            )
        }
      })
      setTableData(defaultData)
    } else {
      setTableData(data)
    }
  }, [searchText])

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true)
    } else {
      setMobileView(false)
    }
  }

  useEffect(() => {
    if (staticHeadRow && staticHeadRowcontainerId && id) {
      manageStaticHeadRow(id, staticHeadRowcontainerId, staticHeadRow)
    }
  }, [staticHeadRowUpdateState, id, tableData])

  useEffect(() => {
    window.addEventListener('load', viewChange)
    window.addEventListener('resize', viewChange)
    return () => {
      window.removeEventListener('resize', viewChange)
    }
  }, [])

  const onRowMouseEnter = (row, e) => {
    if (id !== 'marketOverviewTable' && id !== 'marketShareTable' && id !== 'priceAnalysisTable') {
      onRowMouseEnterF(row, e)
    }
  }
  const onRowMouseLeave = (row, e) => {
    if (id !== 'marketOverviewTable' && id !== 'marketShareTable' && id !== 'priceAnalysisTable') {
      onRowMouseLeaveF(row)
    }
  }
  const onRowClickedEnter = (row, e) => {
    e.stopPropagation()
    setIsRowClick(true)
    if (onRowClicked) {
      onRowClicked(row, e)
    }

    if (!onRowClicked) {
      setCurrentRow(row)
    }
  }

  return !window.location.pathname.includes('/pdf') && (
    <div
      className={`dataTables_wrapper dt-bootstrap4 no-footer ${className || ''}`}
      id={id}>
      {!hideHeader && (
        <Row className={`justify-between align-items-center ${actions ? 'with-export' : ''}`}>
          <Col className="col-7 text-left" sm="4">
            <div className="dataTables_filter">
              <input
                type="search"
                className="form-control form-control-sm w-50"
                placeholder={isSearchWithCountry ? t('table_search_by_country') : t('table_search_by_name')}
                onChange={(ev) => setSearchText(ev.target.value)}
              />
            </div>
          </Col>
          <Col className="col-5 text-right" sm="7">
            <div className="datatable-filter">
              <div
                className={'d-flex align-items-center justify-content-end'}>
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    <span className="d-none d-sm-inline-block">{t('table_pagination_show')}</span>
                    <div className="form-control-select">
                      {' '}
                      <select
                        name="DataTables_Table_0_length"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => setRowsPerPage(e.target.value)}
                        value={rowsPerPageS}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>{' '}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      <div>
        <DataTable
          paginationPerPage={paginationPerPage}
          sortFunction={McPageHelpers.mcDataTableCustomSort}
          data={tableData}
          columns={columns}
          className={className}
          customStyles={customStyles}
          selectableRows={selectableRows}
          selectableRowsComponent={CustomCheckbox}
          expandableRowsComponent={expandedComponent}
          expandableRowsOnClick={true}
          expandableRowExpanded={(row) => (row === currentRow)}
          expandOnRowClicked
          onRowExpandToggled={(bool, row) => setCurrentRow(row)}
          expandableRows={expandedComponent ?? mobileView}
          noDataComponent={<div className="p-2">{t('no_records')}</div>}
          responsive={true}
          onRowMouseEnter={(row, e) => onRowMouseEnter(row.id, e)}
          onRowMouseLeave={(row, e) => onRowMouseLeave(row.id)}
          highlightOnHover
          pointerOnHover
          onRowClicked={(row, e, ...args) => {
            onRowClickedEnter(row.id, e)
            onClick && onClick(row, e, ...args)
          }}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader={fixedHeader}
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          pagination={pagination}
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={
            defaultPagination
              ? null
              : ({
                  currentPage,
                  rowsPerPage,
                  rowCount,
                  onChangePage,
                  onChangeRowsPerPage
                }) => (
                <DataTablePagination
                  customItemPerPage={rowsPerPageS}
                  itemPerPage={rowsPerPage}
                  totalItems={rowCount}
                  paginate={onChangePage}
                  currentPage={currentPage}
                  customCurrentPage={currentPageS}
                  setCurrentPage={setCurrentPage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  onChangePage={onChangePage}
                  setRowsPerPage={setRowsPerPage}
                  className={classNamePagination}
                  isRowClick={isRowClick}
                  showLastUpdate={showLastUpdate}
                />
                )
          }
        />
        {McPageHelpers.generateExplanationFooter(footer)}
        {showLastUpdate && (
          <div className="card-title-group mt-2">
            <div className="card-title">
              <p>{t('table_data_calculation')}
                <span className="ms-1">
                {data && data[0]?.maxYearMonth ? t('table_sp_table_max_year_month', { maxYearMonth: data[0]?.maxYearMonth }) : ''}
              </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default McDataTable
