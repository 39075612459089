import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const BaseModal = ({ isOpen, toggleModal, title, message, icon, href, linkText }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen.keyCode === 27 ? false : isOpen} toggle={toggleModal}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          {icon}
          <h4
            className="nk-modal-title">{title}</h4>
          <div className="nk-modal-text">
            <p className="lead">
              {message}
            </p>
            {!!linkText && <p className="text-soft"><a href={href}>{linkText}</a></p>}
          </div>
          <div className="nk-modal-action mt-5">
            <Button color="light" size="lg" className="btn-mw" onClick={toggleModal}>
              {t('return')}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BaseModal
