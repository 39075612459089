import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import McPageHelpers from '../../McPageHelpers'
import { getCountryName, KEY } from '../../market-overview/MarketOverviewTable/MarketOverviewTable.settings'
import { McDataTable } from '../../../../components/Component'
import { useGlobalStoreData } from '../../../../store/globalStore'
import { usePDFselectedComponentIds } from '../../../../store/pdfStore'

const headCellStyle = { textAlign: 'center' }
const columnDarkStyle = { backgroundColor: '#f4f7f9' }
const staticHeadRowStyle = {
  width: '30%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #00000020',
  borderLeft: '1px solid #00000020'
}

const getColumnBlockByKey = (data, rowKey, statusIsYear, isYearTable, countryName, isDark = null, isPdf = false) => {
  const subTitles = [
    `${McPageHelpers.getColumnsName(data?.prevTimeTag, null, statusIsYear, !isYearTable)}`,
    `${McPageHelpers.getColumnsName(data?.currentTimeTag, null, statusIsYear, !isYearTable)}`,
    `${McPageHelpers.getColumnsName(data?.currentTimeTag, data?.prevTimeTag, statusIsYear, true, true)}`,
    `${McPageHelpers.getColumnsName(data?.currentTimeTag, data?.prevTimeTag, statusIsYear, true, false)} (%)`
  ]

  const countryNameWithSpace = isPdf ? `${countryName} ` : ''

  const block = [
    {
      name: (<div style={headCellStyle}>{countryNameWithSpace + subTitles[0]}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row?.[rowKey]?.prev / 1000), 'decimal', false, 0, 0),
      right: true,
      width: '8%',
      style: isDark ? columnDarkStyle : null,
      sortable: true
    },
    {
      name: (<div style={headCellStyle}>{countryNameWithSpace + subTitles[1]}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row?.[rowKey]?.current / 1000), 'decimal', false, 0, 0),
      right: true,
      width: '8%',
      style: isDark ? columnDarkStyle : null,
      sortable: true
    },
    {
      name: (<div style={headCellStyle}>{countryNameWithSpace + subTitles[2]}</div>),
      selector: row => McPageHelpers.numToLocaleString(Math.round(row?.[rowKey]?.absoluteChange / 1000)),
      right: true,
      width: '8%',
      style: isDark ? columnDarkStyle : null,
      sortable: true
    },
    {
      name: (<div style={headCellStyle}>{countryNameWithSpace + subTitles[3]}</div>),
      selector: row => McPageHelpers.numToLocaleString(row?.[rowKey]?.percentChange, 'percent'),
      right: true,
      width: '6%',
      style: isDark ? columnDarkStyle : null,
      sortable: true
    }
  ]

  return block
}

const ChangeDataTable = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()
  const selectedComponentIds = usePDFselectedComponentIds()

  const {
    data,
    isYearTable,
    pagination,
    isPdf,
    onTableRowClick
  } = props

  const countryChanges = data?.countryChanges
  const statusIsYear = isYearTable ? KEY.YEARLY : KEY.MONTHLY

  const countryNames = {
    world: `${t('world')} ${t('summaryView.import')}`,
    exporter: `${getCountryName(data?.exporterCountry, countriesGSD)} ${t('summaryView.export')}`,
    importer: `${getCountryName(data?.importerCountry, countriesGSD)} ${t('summaryView.import')}`
  }

  const columns = data
    ? [
        {
          name: (<div style={headCellStyle}>{`${t('country')} (${t('thousand_dollar')})`} </div>),
          selector: row => getCountryName(row.countryId, countriesGSD),
          conditionalCellStyles: [
            {
              when: row => row.countryId !== 0,
              style: {
                color: '#798bff',
                textDecoration: 'underline'
              }
            }
          ],
          left: true,
          width: '10%'
        },
        ...getColumnBlockByKey(data, 'worldChangeData', statusIsYear, isYearTable, countryNames.world, columnDarkStyle, isPdf),
        ...getColumnBlockByKey(data, 'exporterChangeData', statusIsYear, isYearTable, countryNames.exporter, null, isPdf),
        ...getColumnBlockByKey(data, 'importerChangeData', statusIsYear, isYearTable, countryNames.importer, columnDarkStyle, isPdf)
      ]
    : []

  useImperativeHandle(ref, () => ({
    generateTablePdf (id) {
      if (selectedComponentIds.includes(id)) {
        return [
          McPageHelpers.generateDataTableContentForPdf(columns, countryChanges, false, 'after')
        ]
      } else return [[null, null], null]
    }
  }))

  return (
    <McDataTable
      data={countryChanges ?? []}
      columns={columns}
      id={'marketOverviewTable-' + new Date().getTime()}
      customSort={McPageHelpers.mcDataTableCustomSort}
      hideHeader
      pagination={pagination}
      staticHeadRowUpdateState={countryNames}
      staticHeadRowcontainerId='change-generated-head'
      staticHeadRow={
      <div style={{ display: 'flex', height: '100%' }}>
        <div className='rdt_TableCol'style={{ width: '10%', borderBottom: '1px solid #00000020' }}>{/* empty cell */}</div>
        <div className='rdt_TableCol'style={staticHeadRowStyle}>{countryNames.world}</div>
        <div className='rdt_TableCol'style={staticHeadRowStyle}>{countryNames.exporter}</div>
        <div className='rdt_TableCol'style={staticHeadRowStyle}>{countryNames.importer}</div>
      </div>
      }
      paginationPerPage={25}
      onClick={onTableRowClick}
    />
  )
})
export default ChangeDataTable
