const CAPTCHA_SITEKEY = '5bde6a63-a572-4524-8299-512762ecdc85'
const NUMBER_OF_BINS = 100
const WORLD_COUNTRY_CODE = 'WORLD'

const BADGE_CODES = {
  IMPORTER_TABLE_SECTION: 'T-IF-01',
  IMPORTER_MAP_BAR_SECTION: 'H-IF-01',
  IMPORTER_BAR: 'G-IF-01',
  POTENTIAL_IMPORTER_TABLE_SECTION: 'T-IF-02',
  POTENTIAL_IMPORTER_MAP_BAR_SECTION: 'H-IF-02',
  POTENTIAL_BAR: 'G-IF-02',
  COMPANY_VALUE_BAR: 'G-UA-01',
  ONLY_HS_COUNT_PIE: 'G-UA-02',
  COMPANY_COUNT_BAR: 'G-UA-03',
  COUNTRY_COUNT_PIE: 'G-UA-04',
  COMPANY_SHIPMENT_COUNT_BAR: 'G-UA-05',
  GROWTH_GRAPH: 'G-UA-06',
  PRICE_ANALYSIS_MAP_1: 'H-FA-01',
  PRICE_ANALYSIS_GRAPH_1: 'G-FA-01',
  PRICE_ANALYSIS_MAP_2: 'H-FA-02',
  PRICE_ANALYSIS_GRAPH_2: 'G-FA-02',
  PRICE_ANALYSIS_GRAPH_3: 'G-FA-03',
  PRICE_ANALYSIS_GRAPH_4: 'G-FA-04',
  PRICE_ANALYSIS_GRAPH_5: 'G-FA-05',
  PRICE_ANALYSIS_GRAPH_6: 'G-FA-06',
  PRICE_ANALYSIS_GRAPH_7: 'G-FA-07',
  PRICE_ANALYSIS_GRAPH_8: 'G-FA-08',
  PRICE_ANALYSIS_TABLE_1: 'T-FA-01',
  MARKET_CHANGE_TABLE_1: 'T-PD-01',
  MARKET_CHANGE_CLUSTERED: 'G-PD-01',
  MARKET_CHANGE_STACKED: 'G-PD-02',
  MARKET_CHANGE_GRAPH_3: 'G-PD-03',
  MARKET_CHANGE_GRAPH_4: 'G-PD-04',
  MARKET_CHANGE_GRAPH_5: 'G-PD-05',
  MARKET_CHANGE_GRAPH_6: 'G-PD-06',
  MARKET_CHANGE_GRAPH_7: 'G-PD-07',
  MARKET_CHANGE_GRAPH_8: 'G-PD-08',
  MARKET_CHANGE_GRAPH_9: 'G-PD-09',
  MARKET_CHANGE_GRAPH_10: 'G-PD-10',
  MARKET_CHANGE_GRAPH_11: 'G-PD-11',
  MARKET_CHANGE_GRAPH_12: 'G-PD-12',
  MARKET_CHANGE_GRAPH_13: 'G-PD-13',
  MARKET_CHANGE_GRAPH_14: 'G-PD-14',
  SUMMARY_TABLES_TABLE_1: 'T-OT-01',
  SUMMARY_TABLES_GRAPH_1: 'H-OT-01',
  SUMMARY_TABLES_TABLE_2: 'T-OT-02',
  SUMMARY_TABLES_GRAPH_2: 'H-OT-02',
  SUMMARY_TABLES_TABLE_3: 'T-OT-03',
  SUMMARY_TABLES_GRAPH_3: 'H-OT-03',
  SUMMARY_TABLES_TABLE_4: 'T-OT-04',
  SUMMARY_TABLES_GRAPH_4: 'H-OT-04',
  SUMMARY_TABLES_TABLE_5: 'T-OT-05',
  SUMMARY_TABLES_GRAPH_5: 'H-OT-05',
  SUMMARY_TABLES_TABLE_6: 'T-OT-06',
  SUMMARY_TABLES_GRAPH_6: 'H-OT-06',
  SUMMARY_TABLES_TABLE_7: 'T-OT-07',
  SUMMARY_TABLES_GRAPH_7: 'H-OT-07',
  SUMMARY_TABLES_TABLE_8: 'T-OT-08',
  SUMMARY_TABLES_GRAPH_8: 'H-OT-08',
  CHANGE_TABLE_09: 'T-OT-09',
  CHANGE_GRAPH_09: 'G-OT-09',
  CHANGE_TABLE_10: 'T-OT-10',
  CHANGE_GRAPH_10: 'G-OT-10'
}

const USA = {
  tr: 'ABD',
  en: 'USA'
}

export const COUNTRY_ABBREVIATIONS = [
  {
    code: 'US',
    tr: 'ABD',
    en: 'USA'
  },
  {
    code: 'AE',
    tr: 'BAE',
    en: 'UAE'
  },
  {
    code: 'CY',
    tr: 'GKRY',
    en: 'GCASC'
  }
]

const TYPE = {
  IMAGE: 'image',
  TABLE: 'table'
}

const CONSTANTS = {
  NUMBER_OF_BINS,
  CAPTCHA_SITEKEY,
  BADGE_CODES,
  WORLD_COUNTRY_CODE,
  USA,
  TYPE
}

export const EU_COUNTRY_CODES = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE']
export const ECOWAS_COUNTRY_CODES = ['BJ', 'BF', 'CV', 'CI', 'GM', 'GH', 'GN', 'GW', 'LR', 'ML', 'NE', 'NG', 'SN', 'SL', 'TG']
export const SACU_COUNTRY_CODES = ['BW', 'SZ', 'LS', 'NA', 'ZA']
export const DUTIES_COUNTRY_CODES = [...EU_COUNTRY_CODES, ...ECOWAS_COUNTRY_CODES, ...SACU_COUNTRY_CODES, 'US', 'CL', 'QA', 'KW', 'JO', 'SA', 'CA', 'LB']

export const RULE_TYPES = [
  'HS_CODE_COUNT',
  'HS_CODE_SET',
  'SEARCH_COUNTRY_SET',
  'MARKET_REPORT_TOTAL_SEARCH_COUNT',
  'MARKET_REPORT_DAILY_SEARCH_COUNT',
  'MARKET_REPORT_ANONYMOUS_DAILY_SEARCH_COUNT',
  'MARKET_REPORT_EXCEL_DOWNLOAD_COUNT'
]

export const AI_HS_SEARCH_ERROR_TYPES = {
  SEARCH_DISABLED: 'SEARCH_DISABLED',
  ANONYMOUS_USER: 'ANONYMOUS_USER',
  THROTTLING: 'THROTTLING'
}

export default CONSTANTS
