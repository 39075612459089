import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

import Constants from '../../../../utils/Constants'
import ChangeCard from './ChangeCard'

const ChangeSection = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { data, isMonthly, onTableRowClick } = props

  const summaryViewChangesRef = useRef(null)

  useImperativeHandle(ref, () => ({
    generateTablesPdf () {
      return [
        ...summaryViewChangesRef.current.generateTablePdf('summaryViewChanges')
      ]
    }
  }))

  return (
    <Row>
      <Col md="12">
        <ChangeCard
          ref={summaryViewChangesRef}
          id={'summaryViewChangesGraph'}
          data={isMonthly ? data?.monthlyChange : data?.yearlyChange}
          isYearTable={!isMonthly}
          title={isMonthly ? t('summaryView.monthlyChange') : t('summaryView.yearlyChange')}
          graphTitle={isMonthly ? t('summaryView.graphMonthlyChange') : t('summaryView.graphYearlyChange')}
          code={isMonthly ? Constants.BADGE_CODES.CHANGE_TABLE_10 : Constants.BADGE_CODES.CHANGE_TABLE_09}
          graphicCode={isMonthly ? Constants.BADGE_CODES.CHANGE_GRAPH_10 : Constants.BADGE_CODES.CHANGE_GRAPH_09}
          isPdf={props?.isPdf}
          onTableRowClick={onTableRowClick}
        />
      </Col>
    </Row>
  )
})

export default ChangeSection
