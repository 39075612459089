import React, { useEffect } from 'react'
import McDataServices, { MarketResultServices, RESPONSE_OBJ_NAMES as RON } from '../../services/McDataServices'
import { useGlobalStoreActions, useGlobalStoreData } from '../../store/globalStore'
import { useSDSimaginalPayload, useSDSsearchTrigger, useSDSselectBoxData, useSDSimaginalUrlSearchParams, useSearchDataStoreActions } from '../../store/searchDataStore'
import DataCoordinator from './DataCoordinator'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { useDataStoreActions, useDSglobalParams } from '../../store/dataStore'
import { ToastContainer } from 'react-toastify'
import useEndpointStatus from '../../store/endpointStatus'
import CONSTANTS from '../../utils/Constants'
import { useRawStoreActions } from '../../store/rawStore'
import * as qs from 'qs'
import { isEmpty } from 'lodash'
import { usePdfStoreActions } from '../../store/pdfStore'

const Initialize = () => {
  const isLoggedInGSD = useGlobalStoreData.isLoggedIn()
  const languageGSD = useGlobalStoreData.language()
  const countriesGSD = useGlobalStoreData.countries()
  const { setCountries } = useGlobalStoreActions()
  const { setExcelStatus } = usePdfStoreActions()
  const payload = useSDSimaginalPayload()
  const selectBoxData = useSDSselectBoxData()
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const searchTrigger = useSDSsearchTrigger()
  const {
    finishSearching,
    startSearching,
    setUrlParamsRelatedSearchParams,
    setSearchedDataCoverage
  } = useSearchDataStoreActions()

  const globalParams = useDSglobalParams()
  const {
    setGlobalSearchedParams,
    setCountryNameAndDataCoverage
  } = useDataStoreActions()

  const { setRawBykey } = useRawStoreActions()

  const endpointStatus = useEndpointStatus()

  const getAllCountriesWithCode = () => {
    McDataServices.getAllCountries().then(countries => {
      countries.find(country => country.countryCode === 'US').countryName = CONSTANTS.USA[languageGSD]
      localStorage.setItem('countries', JSON.stringify(countries))
      setCountries(countries)
    })
  }

  const generateLoggedInActions = () => {
    if (isLoggedInGSD === true) {
      getAllCountriesWithCode()
    }
  }

  const updateSearchParamsHistory = () => {
    if (new URLSearchParams(window.location.search).toString() !== urlSearchParams) {
      const URL = `${process.env.PUBLIC_URL + '?' + urlSearchParams}`
      window.history.pushState(null, 'search', URL)
    }
  }

  const setDataAndExcel = (paramName, data) => {
    setRawBykey(paramName, data)
    setExcelStatus(paramName, !!data[paramName])
  }

  const searchByParams = () => {
    if (searchTrigger) {
      setGlobalSearchedParams(payload.hsCode, payload.importerCountryCode)
      MarketResultServices.SpTable(payload).then(data => setDataAndExcel(RON.SP_TABLE, data))
      MarketResultServices.SpGraph(payload).then(data => setRawBykey(RON.SP_GRAPH, data))
      MarketResultServices.RfTable(payload).then(data => setDataAndExcel(RON.RF_TABLE, data))
      MarketResultServices.SpSummary(payload).then(data => setRawBykey(RON.SP_SUMMARY, data))
      MarketResultServices.Monthly(payload).then(data => setDataAndExcel(RON.MONTHLY, data))
      MarketResultServices.MarketChange(payload).then(data => setRawBykey(RON.MARKET_CHANGE, data))
      MarketResultServices.ComTradeMarketChange(payload).then(data => setRawBykey(RON.COMTRADE_MARKET_CHANGE, data))
      MarketResultServices.ComTradeMarketOverview(payload).then(data => setDataAndExcel(RON.COMTRADE_MARKET_OVERVIEW, data))
      MarketResultServices.ComTradeSummary(payload).then(data => setDataAndExcel(RON.COMTRADE_SUMMARY, data))
      MarketResultServices.Duties(payload).then(data => setDataAndExcel(RON.DUTIES, data))

      // McPageHelpers.handleError(error.response)
    }
  }

  const handleSearchStatus = (endpointStatusData) => {
    const isRequested = Object.values(endpointStatusData).some(
      (status) => status === McPageHelpers.REQUEST_STATUSES.REQUESTED || status === McPageHelpers.REQUEST_STATUSES.IDLE
    )

    if (!isRequested) {
      finishSearching()
      McDataServices.getHsCodes().then(
        hsCode => {
          const labels = []
          payload.hsCode.split(',').forEach(hs => hsCode?.forEach(item => {
            if (item.value === hs) {
              labels.push(item.label)
            }
          }))
          localStorage.setItem('hs', labels.join(' - '))
        }
      )
    }
  }

  const searchByPredefinedParameters = () => {
    const queryString = new URLSearchParams(window.location.search).toString()
    const predefinedSearchParams = qs.parse(queryString)

    if (!isEmpty(predefinedSearchParams) && predefinedSearchParams?.hsCode) {
      setUrlParamsRelatedSearchParams(
        predefinedSearchParams.hsCode,
        predefinedSearchParams.countryToExport,
        predefinedSearchParams.yourCountry,
        predefinedSearchParams.productDesc,
        predefinedSearchParams.summary
      )

      startSearching()
    }
  }

  const updateCountryNameAndDataCoverage = () => {
    if (globalParams.countryCode) {
      const country = McPageHelpers.getCountryDataByCode(selectBoxData.coverageData, globalParams.countryCode)
      const dataCoverageCountry = McPageHelpers.getCountryDataByCode(selectBoxData.coverageData, globalParams.countryCode)
      setCountryNameAndDataCoverage(country?.label, dataCoverageCountry?.dataCoverage)
      setSearchedDataCoverage(dataCoverageCountry?.dataCoverage)
    }
  }

  useEffect(searchByPredefinedParameters, [languageGSD])
  useEffect(generateLoggedInActions, [isLoggedInGSD, languageGSD])
  useEffect(updateCountryNameAndDataCoverage, [globalParams.countryCode, countriesGSD, selectBoxData.coverageData])
  useEffect(updateSearchParamsHistory, [urlSearchParams])
  useEffect(searchByParams, [searchTrigger])
  useEffect(() => handleSearchStatus(endpointStatus), [endpointStatus])

  return (
    <>
      <DataCoordinator/>
      <ToastContainer/>
    </>
  )
}
export default React.memo(Initialize)
