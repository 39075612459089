import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import {
  IconButton,
  Grid,
  Tooltip,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material'
import {
  Close,
  UploadFile,
  Download
} from '@mui/icons-material'

// Store
import { useGlobalStoreData } from '../../../../../store/globalStore'

// Component Imports
import DialogCloseButton from '../atoms/DialogCloseButton'

// Utils
import { emailRegExp } from '../../../utils/constants'
import readExcel from '../../../utils/readExcel'
import { LANGUAGES } from '../../../../../utils/Utils'

const emailValidation = (val) => emailRegExp.test(val)

const getValidExcelFirms = (firms) => {
  const validFirms = firms?.map((firmObj) => {
    const validEmailList = firmObj.emailList?.filter((y) => emailValidation(y))

    return ({
      ...firmObj,
      emailList: validEmailList
    })
  })

  return validFirms?.filter((x) => x?.emailList?.length)
}

const getProcessedExcelData = (data) => {
  if (data?.length) {
    const keys = Object.keys(data[0])

    const processed = data.map((item) => ({
      name: item?.[keys[0]],
      emailList: item?.[keys[1]]?.split(','),
      web: null
    }))

    return processed
  } else {
    return []
  }
}

const listStyles = {
  border: 'thin solid var(--mui-palette-divider)',
  height: 300,
  overflowY: 'auto'
}

const FirmListItem = (props) => {
  const {
    index,
    selectedIndex,
    isValid,
    isActive,
    item,
    setExcelData,
    setSelectedIndex
  } = props

  return (
    <li
      onClick={() => {
        setSelectedIndex(index)
      }}
      className='rounded p-1 mb-1 flex justify-between items-center'
      style={{
        cursor: 'pointer',
        color: isValid ? 'var(--mui-palette-text-primary)' : 'var(--mui-palette-error-main)',
        border: isActive ? '2px solid var(--mui-palette-primary-main)' : 'unset',
        backgroundColor: isValid ? 'var(--mui-palette-action-selected)' : 'var(--mui-palette-error-lightOpacity)'
      }}
    >
      <span>
        {item?.name}
      </span>

      <Close
          style={{
            fontSize: '1.2rem',
            cursor: 'pointer',
            marginRight: 5
          }}
          onClick={() => {
            setExcelData((prevState) => {
              const newState = [...prevState]
              newState.splice(selectedIndex, 1)

              return newState
            })
          }}
        />
    </li>
  )
}

const EmailListItem = (props) => {
  const {
    index,
    selectedIndex,
    isValid,
    item,
    setExcelData
  } = props

  return (
    <li
      key={index}
      className='mb-1'
    >
      <div
        className='rounded flex align-center'
        style={{
          backgroundColor: isValid ? 'var(--mui-palette-action-selected)' : 'var(--mui-palette-error-lightOpacity)',
          width: 'auto',
          maxWidth: '100%'
        }}
      >
        <input
          type='text'
          value={item}
          style={{
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            color: isValid ? 'var(--mui-palette-text-primary)' : 'var(--mui-palette-error-main)',
            padding: '2px 5px',
            flexGrow: 1,
            fontSize: '.72rem',
            lineHeight: '.8rem'

          }}
          onChange={(e) => {
            setExcelData((prevState) => {
              const newState = [...prevState]
              newState[selectedIndex].emailList[index] = e.target.value

              return newState
            })
          }}
        />
        <Close
          style={{
            fontSize: '1rem',
            cursor: 'pointer',
            marginRight: 5
          }}
          onClick={() => {
            setExcelData((prevState) => {
              const newState = [...prevState]
              newState[selectedIndex].emailList = newState[selectedIndex].emailList.filter((x) => x !== item)

              return newState
            })
          }}
        />
      </div>
    </li>
  )
}

const UploadExcelFirmListModalBtn = (props) => {
  const { excelFirms, setExcelFirms } = props

  const { t } = useTranslation()

  const languageGSD = useGlobalStoreData.language()

  const [open, setOpen] = useState(false)
  const [excelData, setExcelData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const excelFilePathName = languageGSD === LANGUAGES.TURKISH ? 'Şablon.xlsx' : 'Template.xlsx'

  const uploadInputRef = useRef()
  const downloadTemplateRef = useRef()

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    const cleanedData = getValidExcelFirms(excelData)
    setExcelFirms(structuredClone(cleanedData))
    handleClose()
  }

  useEffect(() => {
    if (open) {
      setExcelData(excelFirms)
    }
  }, [open])

  return (
    <>
      <IconButton
        onClick={() => { setOpen(true) }}
        color='primary'
      >
        <UploadFile/>
      </IconButton>

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth='md'
        scroll='body'
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogCloseButton onClick={handleClose} disableRipple>
          <Close />
        </DialogCloseButton>
        <DialogTitle
          variant='h4'
          className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-6 sm:pli-16'
        >
          {t('mailTemplate.wizard.firmList.excelModal.title')}
          <Typography component='span' className='flex flex-col text-center'>
            {t('mailTemplate.wizard.firmList.excelModal.subTitle')}
          </Typography>
        </DialogTitle>
        <DialogContent className='overflow-visible pbs-0 sm:pli-16'>
          <Grid container spacing={5}>
            <Grid item xs={12} className='justify-between'>
              <Button
                variant='contained'
                onClick={() => { uploadInputRef.current.click() }}
                startIcon={<UploadFile/>}
                color='primary'
              >
                {t('mailTemplate.wizard.firmList.excelModal.excelUpload')}
              </Button>
              <Tooltip
                title={t('mailTemplate.wizard.firmList.excelModal.excelSampleTooltip')}
                arrow
              >
                <Button
                  variant='contained'
                  onClick={() => { downloadTemplateRef.current.click() }}
                  startIcon={<Download/>}
                  color='primary'
                >
                  {t('mailTemplate.wizard.firmList.excelModal.excelSample')}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ul
                className='rounded p-1'
                style={listStyles}
              >
                {excelData?.map((item, index) => {
                  const isValid = !item?.emailList?.some((x) => !emailValidation(x))
                  const isActive = selectedIndex === index

                  return (
                    <FirmListItem
                      key={index}
                      index={index}
                      selectedIndex={selectedIndex}
                      isValid={isValid}
                      isActive={isActive}
                      item={item}
                      setExcelData={setExcelData}
                      setSelectedIndex={setSelectedIndex}
                    />
                  )
                })}
              </ul>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ul
                className='rounded p-1'
                style={listStyles}
              >
                {excelData?.[selectedIndex]?.emailList?.map((item, index) => {
                  const isValid = emailValidation(item)

                  return (
                    <EmailListItem
                      key={index}
                      index={index}
                      selectedIndex={selectedIndex}
                      isValid={isValid}
                      item={item}
                      setExcelData={setExcelData}
                    />
                  )
                })}
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='justify-center pbs-0 sm:pbe-16 sm:pli-16'>
          <Grid container spacing={5}>
            <Grid item xs={6} className='justify-end'>
              <Button
                variant='contained'
                style={{
                  width: '50%'
                }}
                onClick={handleSubmit}
              >
                {t('mailTemplate.wizard.firmList.excelModal.submit')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='tonal'
                color='secondary'
                onClick={handleClose}
                style={{
                  width: '50%'
                }}
              >
                {t('mailTemplate.wizard.firmList.excelModal.cancel')}
              </Button>
            </Grid>
            <Grid item xs={12} className='pt-1'>
              <span
                style={{
                  fontSize: '.6rem',
                  color: 'var(--mui-palette-text-secondary)'
                }}
              >
                {t('mailTemplate.wizard.firmList.excelModal.submitWarning')}
              </span>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <a
        ref={downloadTemplateRef}
        href={`${process.env.PUBLIC_URL}/assets/mailTemplate/${excelFilePathName}`}
        download={excelFilePathName}
        style={{ display: 'none' }}
      />
      <input
        ref={uploadInputRef}
        type="file"
        onChange={(e) => {
          const file = e.target.files[0]

          readExcel(file).then((result) => {
            const processedData = getProcessedExcelData(result)
            setExcelData(processedData)
          })
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default UploadExcelFirmListModalBtn
