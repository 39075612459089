import React, { useEffect } from 'react'
import { Row, Col, ButtonGroup, Button } from 'reactstrap'
import ButtonDatePicker from '../../../components/datePicker/ButtonDatePicker'
import { REQUEST_STATUSES } from '../McPageHelpers'
import { useSearchDataStoreActions, getPayloadBySearchParams } from '../../../store/searchDataStore'
import { useDataStoreActions } from '../../../store/dataStore'
import { useRawStoreActions } from '../../../store/rawStore'
import { MarketResultServices, RESPONSE_OBJ_NAMES as RON } from '../../../services/McDataServices'
import McDataProcessor from '../McDataProcessor'
import { OVERVIEW_TAB_TYPE } from '../market-overview/MarketOverviewTable/MarketOverviewTable.settings'

const tabTypeDTO = {
  [OVERVIEW_TAB_TYPE.SOURCE]: 'importer',
  [OVERVIEW_TAB_TYPE.DESTINATION]: 'exporter',
  [OVERVIEW_TAB_TYPE.WORLD]: 'world',
  [OVERVIEW_TAB_TYPE.CHANGE]: 'change'
}

const getMaxDate = (isMonthly) => {
  const maxDate = new Date()
  if (isMonthly) {
    maxDate.setDate(0)
  } else {
    maxDate.setFullYear(maxDate.getFullYear() - 1)
  }

  return maxDate
}

const SummaryDateControl = (props) => {
  const {
    comtradeSummaryEndpointStatus,
    monthlyText,
    yearlyText,
    activeAltTab,
    searchParams,
    summaryTablesData
  } = props

  const { setRawBykey } = useRawStoreActions()

  const { setSummaryTablesSectionData } = useDataStoreActions()

  const { setSearchParams } = useSearchDataStoreActions()
  const summaryParams = searchParams?.summary

  const isWorld = tabTypeDTO[activeAltTab] === 'world'
  const activeSummaryParamsObj = isWorld ? summaryParams?.[tabTypeDTO[activeAltTab]]?.importer : summaryParams?.[tabTypeDTO[activeAltTab]]
  const isMonthly = activeSummaryParamsObj?.yearly !== 'true'

  const getSelectedDate = () => {
    return isMonthly
      ? new Date(activeSummaryParamsObj?.yearMonth.toString().substring(0, 4), (parseInt(activeSummaryParamsObj?.yearMonth) - 1/* -1 for backend dates */).toString().substring(4))
      : new Date(activeSummaryParamsObj?.year.toString())
  }

  const setSummaryParam = (key, subKey, val) => {
    let newState

    if (key === 'world') {
      newState = {
        ...summaryParams,
        [key]: {
          importer: {
            ...summaryParams[key].importer,
            [subKey]: val
          },
          exporter: {
            ...summaryParams[key].exporter,
            [subKey]: val
          }
        }
      }
    } else {
      newState = {
        ...summaryParams,
        [key]: {
          ...summaryParams[key],
          [subKey]: val
        }
      }
    }

    setSearchParams('summary', newState)
  }

  const triggerSummaryChange = (key, date, monthly) => {
    const dateKey = monthly ? 'yearMonth' : 'year'

    const year = date.getFullYear()
    const month = date.getMonth() + 1// +1 for backend dates

    const localYearMonth = key === 'world' ? summaryParams[key].importer.yearMonth : summaryParams[key].yearMonth

    const yearMonth = monthly
      ? `${year}${('0' + month.toString()).slice(-2)}`
      : localYearMonth

    const newSearchParams = {
      ...searchParams,
      summary: monthly
        ? {
            ...summaryParams,
            [key]: key === 'world'
              ? {
                  importer: {
                    ...summaryParams[key].importer,
                    yearMonth
                  },
                  exporter: {
                    ...summaryParams[key].exporter,
                    yearMonth
                  }
                }
              : {
                  ...summaryParams[key],
                  yearMonth
                }
          }
        : {
            ...summaryParams,
            [key]: key === 'world'
              ? {
                  importer: {
                    ...summaryParams[key].importer,
                    year
                  },
                  exporter: {
                    ...summaryParams[key].exporter,
                    year
                  }
                }
              : {
                  ...summaryParams[key],
                  year
                }
          }
    }

    const payload = getPayloadBySearchParams(newSearchParams)

    setSummaryParam(key, dateKey, monthly ? yearMonth : year)

    MarketResultServices.ComTradeSummary(payload).then(data => {
      McDataProcessor.generateAndSetSummaryTablesSectionData(data, setSummaryTablesSectionData)
      setRawBykey(RON.COMTRADE_SUMMARY, data)
    })
  }

  const initDateByData = (summaryTablesDataP, summaryParamsP) => {
    if (summaryTablesDataP?.import?.month && summaryTablesDataP?.import?.year && !summaryParamsP?.importer?.yearMonth) {
      setSearchParams('summary', {
        importer: {
          year: summaryTablesDataP.import.year,
          yearMonth: summaryTablesDataP.import.month,
          yearly: summaryParamsP?.importer?.yearly || 'true'
        },
        exporter: {
          year: summaryTablesDataP.export.year,
          yearMonth: summaryTablesDataP.export.month,
          yearly: summaryParamsP?.exporter?.yearly || 'true'
        },
        world: {
          importer: {
            year: summaryTablesDataP.world.import.year,
            yearMonth: summaryTablesDataP.world.import.month,
            yearly: summaryParamsP?.world?.importer?.yearly || 'true'
          },
          exporter: {
            year: summaryTablesDataP.world.export.year,
            yearMonth: summaryTablesDataP.world.export.month,
            yearly: summaryParamsP?.world?.exporter?.yearly || 'true'
          }
        },
        change: {
          year: summaryTablesDataP.change.year,
          yearMonth: summaryTablesDataP.change.month,
          yearly: summaryParamsP?.change?.yearly || 'true'
        }
      })
    }
  }

  useEffect(() => {
    initDateByData(summaryTablesData, summaryParams)
  }, [summaryTablesData, summaryParams])

  return (
    (summaryParams?.importer?.yearMonth && summaryParams?.importer?.year)
      ? <Row style={{ justifyContent: 'end' }}>
          <Col xs='6' lg='4' style={{ alignContent: 'center' }}>
              <ButtonDatePicker
                selected={getSelectedDate()}
                onChange={(date) => triggerSummaryChange(tabTypeDTO[activeAltTab], date, isMonthly)}
                dateFormat={isMonthly ? 'MM/yyyy' : 'yyyy'}
                showMonthYearPicker={isMonthly}
                showFullMonthYearPicker={isMonthly}
                showYearPicker={!isMonthly}
                maxDate={getMaxDate(isMonthly)}
                minDate={new Date('2017')}
                loading={comtradeSummaryEndpointStatus === REQUEST_STATUSES.REQUESTED}
              />
          </Col>
          <Col xs='6' lg='6' style={{ alignContent: 'center' }}>
            <ButtonGroup className="btn-block">
              <Button
                size='sm'
                color="primary"
                className="btn-dim"
                onClick={() => { setSummaryParam(tabTypeDTO[activeAltTab], 'yearly', 'true') }}
                outline={isMonthly}
                >{yearlyText}</Button>
              <Button
                size='sm'
                color="primary"
                className="btn-dim"
                onClick={() => { setSummaryParam(tabTypeDTO[activeAltTab], 'yearly', 'false') }}
                outline={!isMonthly}
                >{monthlyText}</Button>
            </ButtonGroup>
          </Col>
        </Row>
      : null
  )
}

export default SummaryDateControl
