import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGlobalStoreData } from '../../store/globalStore'

import ErrorModal from '../error-modal/error-modal'
import { Icon } from '../Component'

import { HOMEPAGE_URL } from '../../services/api-common'
import { LANGUAGES } from '../../utils/Utils'
import { AI_HS_SEARCH_ERROR_TYPES } from '../../utils/Constants'

const showErrorModal = (fireErrorModal, errorType, t, languageGSD) => {
  switch (errorType) {
    case AI_HS_SEARCH_ERROR_TYPES.SEARCH_DISABLED:
      fireErrorModal(
        t('aiHsSearchErrors.disabledTitle'),
        t('aiHsSearchErrors.disabledText')
      )
      break
    case AI_HS_SEARCH_ERROR_TYPES.ANONYMOUS_USER:
      fireErrorModal(
        t('aiHsSearchErrors.anonymousTitle'),
        t('aiHsSearchErrors.anonymousText'),
        `${HOMEPAGE_URL}/${languageGSD === LANGUAGES.TURKISH ? 'tr/uyeol' : 'en/register'}/`,
        t('aiHsSearchErrors.anonymousLinkText')
      )
      break
    case AI_HS_SEARCH_ERROR_TYPES.THROTTLING:
      fireErrorModal(
        t('aiHsSearchErrors.throttlingTitle'),
        t('aiHsSearchErrors.throttlingText')
      )
      break
  }
}

const AiHsSearchErrorModalHandler = (props) => {
  const { errorType, clearErrorState } = props

  const { t } = useTranslation()

  const languageGSD = useGlobalStoreData.language()

  const [modalFail, setModalFail] = useState(false)
  const [errorModalMessage, setErrorModalMessage] = useState()
  const [errorModalTitle, setErrorModalTitle] = useState()
  const [errorModalHref, setErrorModalHref] = useState(null)
  const [errorModalHrefText, setErrorModalHrefText] = useState(null)

  const fireErrorModal = (title, message, href = null, hrefText = null) => {
    setErrorModalHref(href)
    setErrorModalHrefText(hrefText)
    setErrorModalTitle(title)
    setErrorModalMessage(message)
    setModalFail(true)
  }

  useEffect(() => {
    showErrorModal(
      fireErrorModal,
      errorType,
      t,
      languageGSD
    )
  }, [errorType])

  return (
    <ErrorModal
      isOpen={modalFail}
      toggleModalFail={() => setModalFail((prevState) => !prevState)}
      title={errorModalTitle}
      message={errorModalMessage}
      linkText={errorModalHref ? errorModalHrefText : null}
      href={errorModalHref}
      clearErrorState={clearErrorState}
      icon={<Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-info"></Icon>}
    />
  )
}

export default AiHsSearchErrorModalHandler
